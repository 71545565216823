
const initialState = "MediaFiles";


const MediaFiles = (state = initialState, action) => {
  switch (action.type) {
    case "GETMEDIAFILES":
      return {
        MediaFiles: [],
      };
  default: 
      return state;
  }
};

export default MediaFiles;
