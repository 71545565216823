import React, { useEffect, useRef, useState } from 'react';
import "./BottomBar.sass";
import { FiImage, FiPaperclip, FiSend, FiSmile, } from "react-icons/fi";
import { ImCross, ImFileText2, ImFilePicture } from "react-icons/im";
import { BiLink } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { HiOutlinePhotograph } from "react-icons/hi";
import { BsPaperclip } from "react-icons/bs";
import { useGlobal } from "reactn";
import moment from "moment";
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import message from "../../../actions/message";
import uploadImage from "../../../actions/uploadImage";
import uploadFile from "../../../actions/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import typing from "../../../actions/typing";
import getMyFriends from "../../../actions/getMyFriends";
import getNewRequests from "../../../actions/getNewRequests";
import getGroups from "../../../actions/getGroups";
import Config from '../../../config'




import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  fadeInRight: {
    animation: "x 1s",
    animationDuration: "0.5s",
    animationName: Radium.keyframes(fadeInUp, "fadeOutRight"),
  },
};

const BottomBar = (props) => {
  const [filetabs, setfiletabs] = useState(false);
  const version = useGlobal('version')[0];
  const imageInput = useRef(null);
  const fileInput = useRef(null);
  const typesms = useRef(null);

  const [nav, setNav] = useGlobal('nav');
  const ref = useGlobal('ref')[0];
  const room = useSelector(state => state.io.room);
  const emojis = useSelector(state => state.emoji.sheet);
  const user = useGlobal('user')[0];
  const [userProfile, setuserProfile] = useState(
    JSON.parse(localStorage.getItem("user_profile"))
  );
  const [text, setText] = useState('');
  const [isPicker, showPicker] = useGlobal('isPicker');
  const [pictureRefs, addPictureRef] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPicker){
      showPicker(false);
    }
    //     let blocked = true;
    //    console.log("data - ",room);
    //    console.log("data - ",room);

    // if(room._id==) == 

    if (text === '') dispatch(typing(room, false));
    else dispatch(typing(room, true));
  }, [text])

  const [replyPupUp, setreplyPupUp] = useGlobal('replyPupUp');
  const [replyDetails, setreplyDetails] = useGlobal('replyDetails');
 useEffect(() => {
    typesms.current.focus()
  }, [replyPupUp])


  const sendMessage = () => {
    let newMessage = {};
    setfiletabs(false);
    if (text.length === 0) return;
    let object = {}
    if (!replyPupUp) {
      object = { roomID: room._id, authorID: userProfile._id, content: text, contentType: 'text' }
    } else {
      object = { roomID: room._id, authorID: userProfile._id, content: text, contentType: 'text', type: 'reply', replyAt: replyDetails._id }
    }
    setreplyPupUp(false)
    message(object).then((res) => {
      // console.log(res)
      if (room.isGroup === true) {
        getGroups().then(res => {
          // console.log("Send Message - getGroups", res.data)
          dispatch({ type: Actions.SET_GROUPS, groups: res.data.rooms })
        }).catch(err => console.log(err));
      } else if (room.friendStatus === "Accepted") {
        getMyFriends().then(res => {
          // console.log("Send Message - getMyFriends", res.data)
          dispatch({ type: Actions.SET_MY_FRIENDS, rooms: res.data.rooms })
          setNav("my_friends")
        }).catch(err => console.log(err));
      } else {
        getNewRequests().then(res => {
          // console.log("Send Message - getNewRequests", res.data)
          dispatch({ type: Actions.SET_NEW_REQUESTS, rooms: res.data.rooms })
          setNav("new_requests")
        }).catch(err => console.log(err));
      }
      /*            getRooms()
                      .then(res => {
                          console.log("Send Message", res.data)
                          // dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})
                          if (res.data.rooms.friendStatus === "Accepted") {
                              dispatch({type: Actions.SET_MY_FRIENDS, rooms: res.data.rooms})
                          } else {
                              dispatch({type: Actions.SET_NEW_REQUESTS, rooms: res.data.rooms})
                          }
                      })
                      .catch(err => console.log(err));*/
      // console.log(userProfile)
      if (!replyPupUp) {
        newMessage = {
          _id: res.data.message._id || Math.random(), author: { ...userProfile, _id: userProfile._id }, content: res.data.message.content, type: 'text', date: moment()
        };
      } else {
        newMessage = {
          _id: res.data.message._id || Math.random(),
          author: { ...userProfile, _id: userProfile._id },
          content: res.data.message.content,
          type: 'reply',
          replyAt: res.data.message.replyAt || {},
          date: res.data.message.date || moment()
        };
      }
      dispatch({ type: Actions.MESSAGE, message: newMessage });
    });

    setText('');
    showPicker(false);
  };

  const handleKeyPress = event => {
    if (isPicker){
      showPicker(false);
    }
    if (event.key === 'Enter') sendMessage();
  };

  const sendImages = async images => {
    setfiletabs(false);
    let tmpRefs = [];
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      tmpRefs.push(ref + i);
      const res = await uploadImage(image, ref + i);
      message({ roomID: room._id, authorID: userProfile._id, content: res.data.image.shieldedID, type: 'image' });
      let newMessage = {
        _id: Math.random(),
        author: { ...userProfile, _id: userProfile._id },
        content: res.data.image.shieldedID,
        type: 'image',
        date: moment()
      };
      dispatch({ type: Actions.MESSAGE, message: newMessage });
    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    showPicker(false);
    getRooms().then(res => dispatch({
      type: Actions.SET_ROOMS,
      rooms: res.data.rooms
    })).catch(err => console.log(err));
  };

  const sendFiles = async files => {
    setfiletabs(false);
    for (let i = 0; i < files.length; i++) {
      if ((files[i].size / (1024 * 1024)) > 10) return alert('File exceeds 10MB limit!');
    }
    let tmpRefs = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      tmpRefs.push(ref + i);
      const res = await uploadFile(file, ref + i);
      message({
        roomID: room._id,
        authorID: userProfile._id,
        content: res.data.file.shieldedID,
        type: 'file',
        fileID: res.data.file._id
      });
      let newMessage = {
        _id: Math.random(),
        author: { ...userProfile, _id: userProfile._id },
        content: res.data.file.shieldedID,
        type: 'file',
        date: moment(),
        file: res.data.file,
      };
      dispatch({ type: Actions.MESSAGE, message: newMessage });
    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    showPicker(false);
    getRooms().then(res => dispatch({
      type: Actions.SET_ROOMS,
      rooms: res.data.rooms
    })).catch(err => console.log(err));
  };

  return (
    <div>
      {!props.loading &&
        room &&
        room.isGroup &&
        !room.people.find((el) => el._id === userProfile._id) ? (
        <div></div>
      ) : (
        <div className="sendSms-Wraper">
          <div className={`replyContainerView ${replyPupUp ? "" : "d-none"}`}>
            <div>
              <div>
                <div>
                  <img src={`${Config.srhchat}/${replyDetails.author && replyDetails.author.photo}`} />
                </div>
              </div>
              <div>
                <h3 class="mb-0">
                  {replyDetails.author && replyDetails.author.fullName} {" "}
                  {/* <small>Thursday, September 3, 20220 4:59 PM</small> */}
                  <small>{moment(replyDetails.date).format("dddd, MMM d, Y  h:mm A")}</small>
                </h3>
                <p class="mb-0">
                  {/* <pre>{JSON.stringify(replyDetails,null,2)}</pre> */}

                  {replyDetails.type !== "image" && replyDetails.content && replyDetails.content.substr(0, 35)}
                  {replyDetails.type !== "image" && replyDetails.content && replyDetails.content.length > 35 && "..."}

                  {replyDetails.type == "image" && (<><HiOutlinePhotograph /> Photo</>)}

                </p>
              </div>
              {replyDetails.type && replyDetails.type == "image" ? (<div>
                <img src={`${Config.url}/api/images/${replyDetails.content}`} />
              </div>) : ""}
              <div>
                <ImCross onClick={() => setreplyPupUp(false)} />
              </div>
            </div>
          </div>
          <hr className="m-0"></hr>
          <div
            className={`bottom-bar-conversation ${props.loading ? "d-none" : null
              } uk-flex uk-flex-middle sendSmsContainer`}
          >
            <input
              className="search-input"
              type="text"
              placeholder="Type here..."
              value={text}
             ref={typesms}
              onChange={(e) => setText(e.target.value)}
              data-emoji-input="unicode"
              onKeyPress={handleKeyPress}
              onFocus={() => showPicker(false)}
            />
            <input
              className="file-input"
              type="file"
              ref={fileInput}
              multiple={true}
              onChange={(e) => sendFiles(e.target.files)}
            />
            {/* <div
              className="button attach filetuploader"
              onClick={() =>
                fileInput && fileInput.current && fileInput.current.click()
              }
            >
              <FiPaperclip />
            </div> */}
            <input
              className="file-input"
              type="file"
              ref={imageInput}
              accept="image/*"
              multiple={true}
              onChange={(e) => sendImages(e.target.files)}
            />

            <div>
              <div className={`button file file-container  ${replyDetails.type == "image" && replyPupUp && "d-none"}`}>
                <StyleRoot
                  className={
                    filetabs ? "file-elements active" : "file-elements"
                  }
                  style={styles.fadeInRight}
                >
                  <div
                    onClick={() =>
                      fileInput &&
                      fileInput.current &&
                      fileInput.current.click()
                    }
                  >
                    <small>File</small>
                    <span className="file-link">
                      <ImFileText2 />
                    </span>
                  </div>
                  {/* <div>
                    <small>Link</small>
                    <span className="site-link">
                      <BiLink />
                    </span>
                  </div> */}
                  <div
                    onClick={() =>
                      imageInput &&
                      imageInput.current &&
                      imageInput.current.click()
                    }
                  >
                    <small>Photos</small>
                    <span className="imge-link">
                      <ImFilePicture />
                    </span>
                  </div>
                </StyleRoot>
                {filetabs ? (
                  <FaTimes
                    onClick={() => {
                      setfiletabs(false);
                      showPicker(false);
                    }}
                  />
                ) : (
                  <BsPaperclip
                    onClick={() => {
                      setfiletabs(true);
                      showPicker(false);
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div
              className="button image-attach"
              onClick={() =>
                imageInput && imageInput.current && imageInput.current.click()
              }
            >
              <FiImage />
            </div> */}
            <div className="picker" hidden={!isPicker}>
              <Picker
                onSelect={(emoji) => setText(text + emoji.native)}
                darkMode={false}
                title="Emoji"
                native={true}
                set="facebook"
              />
            </div>
            <div className="button smile" onClick={() => { showPicker(!isPicker); setfiletabs(false); }}>
              <FiSmile />
            </div>

            <div className="button sendsmsbtn" onClick={sendMessage}>
              <FiSend />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BottomBar;
