import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

let MsgPlaceholder = () => {
  return (
    <>
      <div className="msg-wrap">
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-left TopBarAnimated"
          type="media"
          ready={false}
          rows={2}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-left"
          type="media"
          ready={false}
          rows={4}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-right"
          type="media"
          ready={false}
          rows={2}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-left"
          type="media"
          ready={false}
          rows={2}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-right"
          type="media"
          ready={false}
          rows={4}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-left"
          type="media"
          ready={false}
          rows={5}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-right"
          type="media"
          ready={false}
          rows={5}
        ></ReactPlaceholder>
        <ReactPlaceholder
          showLoadingAnimation={true}
          className="chat-iner-componets-left"
          type="media"
          ready={false}
          rows={5}
        ></ReactPlaceholder>
      </div>
    </>
  );
};
export default MsgPlaceholder;
