import {combineReducers} from "redux";
import emoji from './emoji';
import io from './io';
import messages from './messages';
import rtc from './rtc';
import MediaFiles from "./MediaFiles";

export default combineReducers({
  emoji,
  io,
  messages,
  rtc,
  MediaFiles,
});
