import React, { useRef, useState } from "react";
import "./SearchBar.sass";
import { FiSearch } from "react-icons/fi";
import { AiFillCaretDown, AiFillStar } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { useGlobal } from "reactn";
import search from "../../../actions/search";
import imge1 from "../../../assets/imges/icons/dorpDownIcon1.png";
import imge2 from "../../../assets/imges/icons/dropDownIcon2.png";
import imge3 from "../../../assets/imges/icons/dropDownIcon3.png";
import { useDispatch, useSelector } from "react-redux";
import getFavorites from "../../../actions/getFavorites";

import getNewRequests from "../../../actions/getNewRequests";
import getMyFriends from "../../../actions/getMyFriends";
import getGroups from "../../../actions/getGroups";
import getColleagues from "../../../actions/getColleagues";

import Actions from "../../../constants/Actions";

const SearchBar = (props) => {
  const dispatch = useDispatch();
  const searchInput = useRef();
  const setSearchResults = useGlobal("searchResults")[1];
  const [nav, setNav] = useGlobal("nav");
  const [selectedsearch, setselectedsearch] = useGlobal("selectedsearch");
  const [searched, setSearch] = useGlobal("search");


  const [activeStarCheck,setactiveStarCheck] = useGlobal("activeStarCheck");

  let userProfile =  localStorage.getItem("role") ;
  // alert(userProfile);



  // const [inputPlaceHolder, setinputPlaceHolder] = useGlobal("inputPlaceHolder");


  
  const showfavorites = () => {


    // alert()
    getFavorites().then(res => dispatch({
      type: Actions.SET_GROUPS,
      groups: res.data.rooms
    })).catch(err => console.log(err));
  }

  const onChange = (e) => {
    let searched = e.target.value;
    if (nav == "new_requests") {
      getNewRequests(selectedsearch, searched).then(res => dispatch({
        type: Actions.SET_NEW_REQUESTS,
        rooms: res.data.rooms
      })).catch(err => console.log(err));
    }

    if (nav == "my_friends") {
      getMyFriends(selectedsearch, searched).then(res => dispatch({
        type: Actions.SET_MY_FRIENDS,
        rooms: res.data.rooms
      })).catch(err => console.log(err));
    }

    if (nav == "colleagues") {
      getColleagues(selectedsearch, searched).then(res => dispatch({
        type: Actions.SET_COLLEAGUES,
        users: res.data.users
      })).catch(err => console.log(err));
    }

    if (nav == "groups") {
      getGroups(selectedsearch, searched).then(res => dispatch({
        type: Actions.SET_GROUPS,
        groups: res.data.rooms
      })).catch(err => console.log(err));
    }
    // if (nav !== "search") setNav("search");
    // search(e.target.value)
    //   .then((res) => setSearchResults(res.data.users))
    //   .catch((err) => console.log(err));
  };

  let [dropdon, setdropdon] = useState(false);
  let [activetabs, setactivetabs] = useState(false);
  let [inputPlaceHolder, setinputPlaceHolder] = useGlobal("inputPlaceHolder");
  if (!inputPlaceHolder) {
    setinputPlaceHolder("Search Friends & Colleague")
  }
  let getdata = (selected) => {
    if (nav == "new_requests") {
      getNewRequests(selected, searched).then(res => dispatch({
        type: Actions.SET_NEW_REQUESTS,
        rooms: res.data.rooms
      })).catch(err => console.log(err));
    }

    if (nav == "my_friends") {
      getMyFriends(selected, searched).then(res => dispatch({
        type: Actions.SET_MY_FRIENDS,
        rooms: res.data.rooms
      })).catch(err => console.log(err));
    }

    if (nav == "colleagues") {
      getColleagues(selected, searched).then(res => dispatch({
        type: Actions.SET_COLLEAGUES,
        users: res.data.users
      })).catch(err => console.log(err));
    }

    if (nav == "groups") {
      getGroups(selected, searched).then(res => dispatch({
        type: Actions.SET_GROUPS,
        groups: res.data.rooms
      })).catch(err => console.log(err));
    }
  }
  let selectDropDown = (e) => {
    setactivetabs(e);
    setdropdon(!dropdon);
    if (e == 1) {
      setinputPlaceHolder("Search My School Students");
      setselectedsearch("colleagues")
      getdata("colleagues")
    }
    if (e == 2) {
      setinputPlaceHolder("Search All Students");
      setselectedsearch("student")
      getdata("student")
    }
    if (e == 3) {
      setinputPlaceHolder("Search Tutors");
      setselectedsearch("professor")
      getdata("professor")
    }
  };

  return (
    <div className="serchContainers">
      <div>
        <div className="search-bar searchWrap uk-flex uk-flex-center uk-flex-middle">
          <div onClick={() => searchInput.current.focus()}>
            <FiSearch />
          </div>
          <div className="uk-inline search">
            <input
              className="uk-input uk-border-pill"
              placeholder={inputPlaceHolder}
              ref={searchInput}
              onChange={onChange}
            />
          </div>
          <div
            className={`serchDropDownwrap${nav === "groups" ? " d-none" : ""}`}
          >
            <div
              className="searchDrop-icon"
              onClick={() => setdropdon(!dropdon)}
            >
              {dropdon ? <ImCross /> : <AiFillCaretDown />}
            </div>
            <div
              className={
                dropdon
                  ? "serchDropDownContainer active"
                  : "serchDropDownContainer"
              }
            >
              <div  >
                <div
                  className={`${activetabs == 1 ? "activee" : ""} ${
                    userProfile == "professor" ? "d-none" : ""
                  }`}
                  onClick={() => selectDropDown(1)}
                >
                  <span>
                    <img src={imge1} alt="Imge" />
                  </span>
                  <span>My School</span>
                </div>
                <div
                  className={activetabs == 2 ? "activee" : ""}
                  onClick={() => selectDropDown(2)}
                >
                  <span>
                    <img src={imge2} alt="Imge" />
                  </span>
                  <span> Students</span>
                </div>
                <div
                  className={activetabs == 3 ? "activee" : ""}
                  onClick={() => selectDropDown(3)}
                >
                  <span>
                    <img src={imge3} alt="Imge" />
                  </span>
                  <span> Tutors</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="groups"
        onClick={() => {
          showfavorites();
          props.activeFavrate(true);
          setNav("groups");
        }}
      >
       
        <span className={`Favorates ${props.favoritesList ? "active" : null} ${activeStarCheck ? "active": ""}`}>
          <AiFillStar />
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
