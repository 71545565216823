import axios from "axios";
import Config from "../config";

const toggleEmoji = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/emoji/toggle",
        data: { data }
    });
};

export default toggleEmoji;
