import axios from "axios";
import Config from "../config";

const ForwardMessage = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + '/api/message/forward',
        data
    });
};

export default ForwardMessage;
