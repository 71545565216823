import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import UpdateSetting from '../../../actions/profileSettingLaraval'
import profileSettingStudent from "../../../actions/profileSettingNodeStudent";
let SettingOptions = (props) => {
  // const user = useGlobal("user")[0]; 
  const user = JSON.parse(localStorage.getItem("user_id"));

  let [option, setOption] = useState(props.ProfileSettings);

  const updateProfile = () => {
    console.log("user", user);
    const data = {
      settings: {
        email_permission: false,
        phone_permission: false,
        profile_permission: !option,
        system_notification: false,
        email_notification: false
      },
      user_id: user
    };

    // UpdateSetting(data).then((res) => {
    //   console.log(res)
    // });
    profileSettingStudent(data).then((res) => {
      console.log("res", res);
    });
  }

  return (
    <div>
      {props.text}
      {/* = props= {JSON.stringify(props.ProfileSettings, null, 2)}
          = state = {JSON.stringify(option, null, 2)} */}
      <span
        onClick={() => {
          setOption(!option);
          updateProfile();
        }}
        className={
          option ? "togglersetting-wrap activee" : "togglersetting-wrap "
        }
      >
        <span></span>
      </span>
    </div>
  );
}

export default SettingOptions;