import React, { useEffect, useRef, useState } from "react";
import "./TopBar.sass";
import {
  FiArrowLeft,
  FiMoreHorizontal,
  FiSettings,
  FiStar,
} from "react-icons/fi";
import { AiFillFile } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { IoIosStarOutline } from "react-icons/io";
import { FaBell, FaUserAlt, FaBan, FaFileAlt } from "react-icons/fa";
import { useGlobal } from "reactn";
import Picture from "../../../components/Picture";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import toggleFavorite from "../../../actions/toggleFavorite";
import { useHistory } from "react-router-dom";
import getMeetingRoom from "../../../actions/getMeetingRoom";
import getRoom from "../../../actions/getRoom";
import postCall from "../../../actions/postCall";
import Actions from "../../../constants/Actions";
import moment from "moment";

import Config from "../../../config";
import logo from "../../../assets/imges/logo.png";
import "react-placeholder/lib/reactPlaceholder.css";
import toggleFriend from "../../../actions/toggleFriend";
import toggleGroup from "../../../actions/toggleGroup";
import JoinGropLoader from "../../../components/JoinGroupLoader";
import imge1 from "../../../assets/imges/icons/schoolimge1.png";
import imge2 from "../../../assets/imges/icons/dropDownIcon2.png";
import imge3 from "../../../assets/imges/icons/dropDownIcon3.png";
import toggleBlock from "../../../actions/toggleBlock";
import message from "../../../actions/message";
import getNewRequests from "../../../actions/getNewRequests";
import getMyFriends from "../../../actions/getMyFriends";
import getColleagues from "../../../actions/getColleagues";
import getGroups from "../../../actions/getGroups";

import GroupImge from "../../../assets/images/imge.jpg";

import { Modal } from "react-bootstrap";
import { FaRegCalendarAlt, FaRegBuilding, FaFlag } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { SiGooglescholar } from "react-icons/si";
import { ImPhone, ImLocation2, ImFilePicture } from "react-icons/im";
import { HiOutlineLocationMarker } from "react-icons/hi";

import porfileimge from "../../../assets/imges/users/user-2.jpg";

import GalaryImge1 from "../../../assets/imges/gallery/imge2.jpg";
import GalaryImge2 from "../../../assets/imges/gallery/imge4.jpg";
import GalaryImge3 from "../../../assets/imges/gallery/imge11.jpg";

import BlockLoader from "./BlockerLoader";
import getProfile from '../../../actions/getProfile'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const TopBar = ({ back, loading, showMediaFiles }) => {
  const [chatBlockByme, setchatBlockByme] = useGlobal("chatBlockByme");
  const [chatBlockByuser, setchatBlockByuser] = useGlobal("chatBlockByuser");
  // const [block, setblock] = useGlobal("block");
  // const [blocked, setblocked] = useGlobal("blocked");
  const [blockedLoader, setbblockedLoader] = useState(false);
  let blockedchager = async () => {
    setbblockedLoader(true);
    const res = await toggleBlock({ Room_id: room._id, isGroup: room.isGroup });
    // setblocked(res.data.blocked);
    setchatBlockByme(!chatBlockByme);
    setbblockedLoader(false);
  };
  const [userProfile, setuserProfile] = useState(
    JSON.parse(localStorage.getItem("user_profile"))
  );
  let [showdropDown, setshowdropDown] = useState(false);
  const [profileDetailsM, setProfileModal] = useGlobal("user");
  const [ProfileModal, setprofileDetailsM] = useState(profileDetailsM);
  const [ProfileModalCon, setProfileModalCon] = useState(false);
  const [GalaryImgesModal, setGalaryImgesModal] = useState(false);
  const [text, setText] = useState("");
  const [isPicker, showPicker] = useGlobal("isPicker");

  let MediaFileList = useSelector((state) => state.io.messages);

  let ImagesList = MediaFileList.filter((data) => {
    return data.type == "image";
  });

  const searchInput = useRef();
  const onlineUsers = useSelector((state) => state.io.onlineUsers);
  const room = useSelector((state) => state.io.room) || {};
  // const memberlength =room.people.lengt;
  const user = useGlobal("user")[0];
  const [favorites, setFavorites] = useGlobal("favorites");
  const setNav = useGlobal("nav")[1];
  const setAudio = useGlobal("audio")[1];
  const setVideo = useGlobal("video")[1];
  const setCallDirection = useGlobal("callDirection")[1];
  const setMeeting = useGlobal("meeting")[1];
  const [people, setPeople] = useGlobal("people");
  const [user_profile, setUser_profile] = useGlobal('user_profile');

  const [addFriendMessage, setAddFriendMessage] = useState("Add Friend");
  const [searchValue, setsearchValue] = useState();

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const [SearchBar, setSearchBar] = useState(true)

  let other = {};

  room.people &&
    room.people.forEach((person) => {
      if (user_profile.user_id != person.user_id) other = person;
    });
  // console.log(room)
  const [otherProfile, setotherProfile] = useState([])
  if (!other.firstName) {
    other = { ...other, firstName: "Deleted", lastName: "User" };
  }



  const fetchProfile = async () => {
    let id = other._id
    setProfileModalCon(true);
    await getProfile(id).then(res => {
      // console.log(res.data)
      setotherProfile(res.data)
    }).catch(error => {
      console.log(error)
    });
    // if(res.data){
    //   user_profile = res.data;
    // }
  };


  const title = (
    room.isGroup ? room.title : `${other.firstName.charAt(0).toUpperCase() + other.firstName.slice(1)} ${other.lastName.charAt(0).toUpperCase() + other.lastName.slice(1)}`
  ).substr(0, 22);
  const role = room.isGroup ? room.user_type : `${other.user_type}`;
  const schoolname = room.school_name ? room.school_name : other.school_name;

  const warningToast = (content) => {
    addToast(content, {
      appearance: "warning",
      autoDismiss: true,
    });
  };

  const errorToast = (content) => {
    addToast(content, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const onChange = (e) => {
    setsearchValue(e.target.value);
    let searched = e.target.value;
    // console.log(searched)
    getRoom(room._id, searched)
      .then((res) => {
        dispatch({ type: Actions.SET_ROOM, room: res.data.room });
        dispatch({
          type: Actions.SET_MESSAGES,
          messages: res.data.room.messages,
        });
        dispatch({
          type: Actions.MESSAGES_REMOVE_ROOM_UNREAD,
          roomID: room._id,
        });
      })
      .catch((err) => {
        dispatch({ type: Actions.SET_ROOM, room: null });
        dispatch({ type: Actions.SET_MESSAGES, messages: [] });
        if (!err.response || err.response.status !== 404);
      });
    // if (nav == "my_friends") {
    //     getMyFriends(selectedsearch, searched).then(res => dispatch({
    //         type: Actions.SET_MY_FRIENDS,
    //         rooms: res.data.rooms
    //     })).catch(err => console.log(err));
    // }

    // if (nav == "colleagues") {
    //     getColleagues(selectedsearch, searched).then(res => dispatch({
    //         type: Actions.SET_COLLEAGUES,
    //         users: res.data.users
    //     })).catch(err => console.log(err));
    // }

    // if (nav == "groups") {
    //     getGroups(selectedsearch, searched).then(res => dispatch({
    //         type: Actions.SET_GROUPS,
    //         groups: res.data.rooms
    //     })).catch(err => console.log(err));
    // }
    // if (nav !== "search") setNav("search");
    // search(e.target.value)
    //   .then((res) => setSearchResults(res.data.users))
    //   .catch((err) => console.log(err));
  };

  const call = async (isVideo) => {
    if (
      onlineUsers.filter((u) => u.id === other.user_id).length === 0 &&
      !room.isGroup
    )
      return warningToast("Can't call user because user is offline");
    await setAudio(true);
    await setVideo(isVideo);
    await setCallDirection("outgoing");
    dispatch({ type: Actions.RTC_SET_COUNTERPART, counterpart: other });
    try {
      const res = await getMeetingRoom({
        startedAsCall: true,
        caller: user_profile.user_id,
        callee: other._id,
        callToGroup: room.isGroup,
        group: room._id,
      });
      await setMeeting(res.data);
      history.replace("/meeting/" + res.data._id);
      await postCall({ roomID: room._id, meetingID: res.data._id });
    } catch (e) {
      errorToast("Server error. Unable to initiate call.");
    }
  };


  const [activeStarCheck, setactiveStarCheck] = useGlobal("activeStarCheck");

  const favorite = async () => {
    const res = await toggleFavorite(room._id);
    // setNav('favorites');
    setFavorites(res.data.favorites);
    setactiveStarCheck(true);
    await dispatch({
      type: Actions.SET_GROUPS,
      groups: res.data.favorites,
    });


    // await setFavorites(res.data.favorites);
    // console.log("favorites: ",favorites)
  };

  // const ShowPeople = () => {
  //     let p = []
  //     if (people) {
  //         for (let i = 0; i < people.length; i++) {
  //             p.push(people[i].fullName)
  //         }
  //     }
  //     console.log(p)
  // }

  const isFavorite = () => {
    if (favorites) {
      for (let favorite of favorites) {
        if (favorite._id === room._id) return true;
      }
    }
    return false;
  };
  const toggleFriendRequest = async (Status) => {
    setjoingRequest(true);
    const res = await toggleFriend(room._id, Status);
    // console.log("toggleFriendRequest", res.data)
    if (res.data.room.friendStatus === "Requested") {
      setAddFriendMessage("Request Sent");
    } else if (res.data.room.friendStatus === "Accepted") {
      setAddFriendMessage("Request Accepted");
    }
    const res1 = await dispatch({
      type: Actions.SET_ROOM,
      room: res.data.room,
    });
    setjoingRequest(false);
    // getRoom(room._id)
    //     .then((res) => {
    //         dispatch({ type: Actions.SET_ROOM, room: res.data.room });
    //         dispatch({
    //             type: Actions.SET_MESSAGES,
    //             messages: res.data.room.messages,
    //         });
    //         dispatch({ type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: room._id });
    //     })
    //     .catch((err) => {
    //         dispatch({ type: Actions.SET_ROOM, room: null });
    //         dispatch({ type: Actions.SET_MESSAGES, messages: [] });
    //     });
    // setNav('my_friends');
    //setNav('favorites');
    //setFavorites(res.data.favorites);
  };

  const [joingRequest, setjoingRequest] = useState(false);

  const toggleJoinGroup = async () => {
    setjoingRequest(true);
    const res = await toggleGroup(room._id);
    if (res.data.room) {
      message({
        roomID: room._id,
        authorID: userProfile._id,
        content: "Joined Group",
        type: "joingroup",
      }).then(() => {
        if (room.isGroup === true) {
          getGroups()
            .then((res) => {
              // console.log("Send Message - getGroups", res.data)
              dispatch({ type: Actions.SET_GROUPS, groups: res.data.rooms });
            })
            .catch((err) => console.log(err));
        } else if (room.friendStatus === "Accepted") {
          getMyFriends()
            .then((res) => {
              // console.log("Send Message - getMyFriends", res.data)
              dispatch({ type: Actions.SET_MY_FRIENDS, rooms: res.data.rooms });
              setNav("my_friends");
            })
            .catch((err) => console.log(err));
        } else {
          getNewRequests()
            .then((res) => {
              // console.log("Send Message - getNewRequests", res.data)
              dispatch({
                type: Actions.SET_NEW_REQUESTS,
                rooms: res.data.rooms,
              });
              setNav("new_requests");
            })
            .catch((err) => console.log(err));
        }
      });
      let newMessage = {
        _id: Math.random(),
        author: { ...user, _id: userProfile._id },
        content: "Joined Group",
        type: "joingroup",
        date: moment(),
      };
      dispatch({ type: Actions.MESSAGE, message: newMessage });
      setText("");
      showPicker(false);
    }
    // console.log("toggleJoinGroup", res.data)
    // if(res.data.room.friendStatus==="Requested"){
    // }
    const ress = await dispatch({
      type: Actions.SET_ROOM,
      room: res.data.room,
    });
    setjoingRequest(false);
    // getRoom(room._id)
    //     .then((res) => {
    //         dispatch({ type: Actions.SET_ROOM, room: res.data.room });
    //         dispatch({
    //             type: Actions.SET_MESSAGES,
    //             messages: res.data.room.messages,
    //         });
    //         dispatch({ type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: room._id });
    //     })
    //     .catch((err) => {
    //         dispatch({ type: Actions.SET_ROOM, room: null });
    //         dispatch({ type: Actions.SET_MESSAGES, messages: [] });
    //     });
    // setNav('my_friends');
    //setNav('favorites');
    //setFavorites(res.data.favorites);
  };

  const roomInfo = () => {
    history.replace("/room/" + room._id + "/info");
  };

  const Online = ({ other }) => {
    const onlineUsers = useSelector((state) => state.io.onlineUsers);
    const prevStatusRef = useRef(false);
    const [lastOnline, setLastOnline] = useState(null);

    useEffect(() => {
      if (
        prevStatusRef.current &&
        onlineUsers.filter((u) => u.id === other._id).length === 0
      )
        setLastOnline(moment().valueOf());
      prevStatusRef.current =
        onlineUsers.filter((u) => u.id === other._id).length > 0;
    }, [onlineUsers, other]);

    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "Online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "Away")
        .length > 0
    )
      return "away";
    if (lastOnline) return `Last online: ${moment(lastOnline).fromNow()}`;
    return `Last online: ${other.lastOnline ? moment(other.lastOnline).fromNow() : "Never"
      }`;
  };

  const getStatus = () => {
    if (room.isGroup) return null;
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "Busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "Online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "Away")
        .length > 0
    )
      return "away";
    return null;
  };
  return (
    <>
      <div className="top-bar uk-flex uk-flex-between uk-flex-middle topBarHeight">
        <div className="nav uk-flex uk-flex-middle">
          <div className="button mobile" onClick={back}>
            <FiArrowLeft />
          </div>
          {!loading && (
            <div className="uk-flex uk-flex-middle">
              <div className="profile conversation">
                <Picture
                  user={other}
                  group={room.isGroup}
                  picture={room.picture}
                  title={room.title}
                  room={room}
                />
              </div>
              {getStatus() && <div className={`dot ${getStatus()}`} />}
            </div>
          )}
          {!loading && (
            <div className="text">
              <div className="title">
                {title}
                {title.length > 22 && "..."}
                <span
                  className={`button${isFavorite() ? " active startActive" : ""
                    } `}
                  onClick={favorite}
                >
                  <IoIosStarOutline />
                </span>

                {blockedLoader && <BlockLoader />}
                {!blockedLoader && !chatBlockByuser && (
                  <span
                    className={`${!room.isGroup ? "block-elements " : "d-none"
                      }`}
                  >
                    <span
                      onClick={blockedchager}
                      className={
                        chatBlockByme == true
                          ? "block-success blocked"
                          : "block-success"
                      }
                      title={
                        chatBlockByme == true
                          ? "Blocked"
                          : "UnBlocked"
                      }
                    >
                      <FaBan />
                    </span>
                    <span
                      onClick={blockedchager}
                      className={
                        chatBlockByme == false
                          ? "block-danger blocked"
                          : "block-danger"
                      }
                      title={
                        chatBlockByme == false
                          ? "UnBlocked"
                          : "Blocked"
                      }
                    >
                      <FaBan />
                    </span>
                  </span>
                )}
              </div>
              <div className="message">
                {room.isGroup &&
                  room.people.map((data, index) => {
                    if (index > 2) return;
                    return (
                      <>
                        {data.fullName}
                        {index <= 1 ? "," : ""}
                      </>
                    );
                  })}
                {room.people && room.people.length > 3 ? "..." : null}
                {!room.isGroup && <Online other={other} />}
                {/* {room.isGroup ? (
                    `Group: ${room.people.length} members`
                  ) : (
                    <Online other={other} />
                  )} */}
              </div>
            </div>
          )}
          {/* <div>
              {loading && (
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="rect"
                  style={{ width: 50, height: 50 }}
                  ready={false}
                  rows={2}
                />
              )}
            </div> */}
        </div>
        {!loading && (
          <div className="nav align-items-center pr-3">
            {/*                 <div className="button" onClick={() => call(true)}>
                    <FiVideo/>
                </div>
                <div className="button" onClick={() => call(false)}>
                    <FiPhone/>
                </div> 
                */}
            {SearchBar && (
              <div>
                {role == "schooladmin" ? (
                  <span className="userStatuschat a bg-transparent schoolAdminTitle">
                    <span>
                      <img src={imge1} alt="Imge" />
                    </span>
                    {/* <span>{role}</span> */}
                    <span>School Admin ({schoolname})</span>
                  </span>
                ) : (
                  ""
                )}
                {role == "student" ? (
                  <span className="userStatuschat b">
                    <span>
                      <img src={imge2} alt="Imge" />
                    </span>
                    {/* <span>{role}</span> */}
                    <span>Student ({schoolname})</span>
                  </span>
                ) : (
                  ""
                )}
                {role == "professor" ? (
                  <span className="userStatuschat c bg-transparent">
                    <span>
                      <img src={imge3} alt="Imge" />
                    </span>
                    {/* <span>{role}</span> */}
                    {/* <span>Tutor</span> */}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
            <div>
              {SearchBar && room.isGroup && (
                <div className="groups-imges">
                  {room.people.map((data, index) => {
                    if (index > 2) return;
                    return (
                      <span key={index}>
                        <img src={Config.srhchat + data.photo} />
                      </span>
                    );
                  })}

                  {/* <span>
                    <img src={GroupImge} />
                  </span>
                  <span>
                    <img src={GroupImge} />
                  </span> */}
                </div>
              )}
            </div>
            {SearchBar &&
              room.isGroup &&
              room.people &&
              room.people.length > 3 && (
                <div>
                  <div className="other-user-wrap">
                    <div>
                      <span className="show-group-users">
                        +{room.people && room.people.length - 3} More
                      </span>
                    </div>
                    <div className="more-users-container ">
                      <div className="GroupMemberListShow">
                        {room.people.map((data, index) => {
                          if (index < 3) return;
                          return <div>{data.fullName}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {joingRequest ? (
              <JoinGropLoader />
            ) : (
              <span className={loading ? "d-none" : null}>
                {!room.isGroup &&
                  // room.friendActionUser === userProfile._id &&
                  room.friendStatus === "Pending" && (
                    <div
                      className="userStatuschat a mx-2 rounded"
                      onClick={() => toggleFriendRequest("Requested")}
                    >
                      Add Friend
                    </div>
                  )}
                {!room.isGroup &&
                  room.friendActionUser === userProfile._id &&
                  room.friendStatus === "Requested" && (
                    <div className="joinGrouped  mx-2 rounded">
                      Request Sent
                    </div>
                  )}
                {!room.isGroup &&
                  room.friendActionUser != userProfile._id &&
                  room.friendStatus === "Requested" && (
                    <div
                      className="joinGrouped   mx-2 rounded"
                      onClick={() => toggleFriendRequest("Accepted")}
                    >
                      Accept Request
                    </div>
                  )}
                {room.isGroup &&
                  !room.people.find((el) => el._id === userProfile._id) && (
                    <div
                      className="joinGroup  mx-2 rounded"
                      onClick={toggleJoinGroup}
                    >
                      Join Group
                    </div>
                  )}
                {room.isGroup &&
                  room.people.find((el) => el._id === userProfile._id) && (
                    <div className="joinGrouped  mx-2 rounded">Joined</div>
                  )}
              </span>
            )}

            <div>
              <div className={`side-search-bar ${SearchBar ? "bordered" : ""}`}>
                <span onClick={() => setSearchBar(false)}>
                  <FiSearch />
                </span>
                <span>
                  <input
                    type="text"
                    placeholder="Search this conversation"
                    onChange={onChange}
                    value={searchValue}
                  />{" "}
                </span>
                <span onClick={() => setSearchBar(true)}>
                  <FaTimes />
                </span>
              </div>
            </div>

            {/* ******************** */}
            {/* ******************** */}

            {/* ************************* */}
            <div className="chat-header-side-options ">
              <div
                className="header-side-dropdown"
                type="button"
                onClick={() => {
                  setshowdropDown(!showdropDown);
                }}
              >
                {showdropDown ? <FaTimes /> : <FiMoreHorizontal />}
              </div>

              <div
                className={
                  showdropDown
                    ? "chat-option-dropdown active"
                    : "chat-option-dropdown"
                }
              >
                <div
                  onClick={() => {
                    setGalaryImgesModal(true);
                    setshowdropDown(false);
                  }}
                >
                  <ImFilePicture /> Gallery
                </div>
                <div
                  onClick={() => {
                    showMediaFiles();
                    setshowdropDown(false);
                  }}
                >
                  <AiFillFile /> Files
                </div>
                <div
                  className={`${room.isGroup ? "d-none" : ""}`}
                  onClick={() => {
                    fetchProfile()

                    setshowdropDown(false);
                  }}
                >
                  <FaFileAlt /> View Profile
                </div>
              </div>
            </div>
            {/* ************************* */}
          </div>
        )}
      </div>

      {/* Gallery Modal */}
      <Modal
        show={GalaryImgesModal}
        onHide={() => {
          setGalaryImgesModal(false);
        }}
        className="GralryModal"
        dialogClassName="modal-90w GralryModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <div className="GalaryHeader">
            <div>
              <Picture
                user={other}
                group={room.isGroup}
                picture={room.picture}
                title={room.title}
                room={room}
              />
              {/* <img src={Config.srhchat + ProfileModal.photo} alt="P" /> */}
            </div>
            <div>
              <h4>
                {room.isGroup
                  ? room.title
                  : other.firstName + " " + other.lastName}
              </h4>
              {/* <p>3/3/2021 at 3:39PM</p> */}
            </div>
          </div>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {ImagesList.length == 0 ? (
            "No Photo Found"
          ) : (
            <div className="GalaryMediaCarasole">
              <Carousel>
                {ImagesList.map((data) => {
                  return (
                    <div>
                      <img src={Config.url + "/api/images/" + data.content} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* profileModal */}

      {/* profileModal */}
      <Modal
        show={ProfileModalCon}
        onHide={() => setProfileModalCon(false)}
        centered
        className="profileModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="user-profile-container" id="user-profile-container">
            <div className="profile-container">
              {otherProfile.length == 0 && <BlockLoader />}
              {otherProfile.length !== 0 && (
                <div className="porfile-wrap">
                  {/* <pre> {JSON.stringify(otherProfile,null,2)}</pre> */}
                  <div className="profile-imge">
                    <img src={otherProfile && otherProfile.photo ? Config.srhchat + otherProfile.photo : "#"} alt="P" />
                    <span className="status-dot waiting"></span>
                  </div>
                  {/* <pre>{JSON.stringify(otherProfile, null, 2)}</pre> */}
                  <div className="user-details-container">
                    <div>
                      <h4> {otherProfile.fullName}  </h4>
                    </div>
                    <div className="porfile-user-list">
                      <span>First Name</span>
                      <span>
                        <FiUser /> {otherProfile.firstName}
                      </span>
                    </div>
                    <div className="porfile-user-list">
                      <span>Last Name</span>
                      <span>
                        <FiUser /> {otherProfile.lastName}
                      </span>
                    </div>
                    <div className={`porfile-user-list ${otherProfile.settings.profile_permission == true ? "" : "d-none"}`}>
                      <span>Email</span>
                      <span className="emailOverflow">
                        <AiOutlineMail /> {otherProfile.email}
                      </span>
                    </div>
                    <div className={`porfile-user-list ${otherProfile.phone && otherProfile.settings.profile_permission == true ? "" : "d-none"}`}>
                      <span>Contact</span>
                      <span>
                        <ImPhone />
                        {otherProfile.phone}
                      </span>
                    </div>
                    <div className={`porfile-user-list ${otherProfile.settings.profile_permission == true ? "" : "d-none"}`}>
                      <span>DOB</span>
                      <span>
                        <FaRegCalendarAlt /> {otherProfile.dob}
                      </span>
                    </div>

                    <div className="porfile-user-list">
                      <span>Role</span>
                      <span>
                        <div className="profile-details-iner">
                          <div>
                            <SiGooglescholar />
                          </div>
                          <div>{otherProfile.user_type == "student" ? "Student" : otherProfile.user_type == "schooladmin" ? "School Admin" : "Professor"}</div>
                        </div>
                      </span>
                    </div>


                    {/* <div className="porfile-user-list">
                    <span>Gender</span>
                    <span>
                      <FiUser />
                      {otherProfile.gender}
                    </span>
                  </div> */}
                  </div>
                  <div className="user-details-container">
                    <div>
                      <h5>Location</h5>
                    </div>
                    <div className="porfile-user-list">
                      <span>Country</span>
                      <span>
                        <ImLocation2 /> {otherProfile.country}
                      </span>
                    </div>
                    <div className="porfile-user-list">
                      <span>City</span>
                      <span>
                        <FaRegBuilding /> {otherProfile.city}
                      </span>
                    </div>
                    <div className="porfile-user-list">
                      <span>State</span>
                      <span>
                        <FaFlag /> {otherProfile.state}
                      </span>
                    </div>
                  </div>

                </div>

              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* profileModal */}
    </>
  );
};

export default TopBar;
