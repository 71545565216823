import {setGlobal} from 'reactn';

const init = async () => {
    document.addEventListener('gesturestart', e => {
        e.preventDefault();
    });

    //  OLD TOKEN CHECKS START

    /*

    if (token) {
            var authToken = localStorage.getItem('authtoken')
            var roleVal = localStorage.getItem('role')
            var splitPath = window.location.pathname.split('/');
            var routeval = splitPath[1];
            if(routeval == "login" && splitPath[2]){
                let accesstoken_split = splitPath[2].split('&');
                authToken = accesstoken_split[0]
                roleVal = accesstoken_split[1]
            }
            let result;
            let status
            var url = Config.srhchat;
            var redirectUrl = "";
            try {
                if(roleVal == 1 || roleVal == 2){
                    url = url + "api/student/chat-status"
                    redirectUrl = Config.redirectstudent;
                }
                else{
                    url = url + "tutor/chat-status"
                    redirectUrl = Config.redirectstudent;
                }
                const res = await axios({
                    method: "get",
                    url: url,
                    headers: {
                        'X-Authorization': `Bearer ${authToken}`,
                      }
                });
                console.log('Check User Response',res);
                status=res.status;
                console.log('status code',status);
                result = res.data;
            } catch (e) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("authtoken");
                localStorage.removeItem('school_id')
                localStorage.removeItem('email')
                token = localStorage.getItem('token');
                userString = localStorage.getItem('user');
                user = userString ? JSON.parse(userString) : null;
                console.log(e);
                result = null;
                window.location = redirectUrl
            }
            if(status==401){
                console.log("User not authorized");
            }
            // if (!result || result.error) {
            //     localStorage.removeItem("token");
            //     localStorage.removeItem("user");
            //     token = localStorage.getItem('token');
            //     userString = localStorage.getItem('user');
            //     user = userString ? JSON.parse(userString) : null;
            // }
        }
        else{
            var splitPath = window.location.pathname.split('/');
            var routeval = splitPath[1];
            var redirectUrl = "";
            if(routeval){
                var tokenval = splitPath[2];
                if(tokenval){
                    var resultdata = tokenval.split('&');
                    console.log(resultdata ,'dsfdf')
                    var tokenVal = resultdata[0]
                    localStorage.setItem('authtoken' , tokenVal)
                    var roleVal = resultdata[1]
                    localStorage.setItem('role' , roleVal)
                    var url = Config.srhchat;
                    var loginUrl = Config.srhchat;
                    try{
                        if(roleVal == 1 || roleVal == 2){
                            url = url + "api/student/chat-status"
                            loginUrl = loginUrl + "api/get-student-profile"
                            redirectUrl = Config.redirectstudent;
                        }
                        else{
                            url = url + "api/tutor/chat-status"
                            loginUrl = loginUrl + "api/tutor/get-profile"
                            redirectUrl = Config.redirecttutor;
                        }
                        var response = await axios({
                        method: "get",
                        url: url,
                        headers: {
                            'X-Authorization': `Bearer ${tokenVal}`,
                          }
                    });

                    if(response){
                        var response = await axios({
                            method: "get",
                            url: loginUrl,
                            headers: {
                                'X-Authorization': `Bearer ${tokenVal}`,
                              }
                        });
                        if(response){
                            let email = (response.data ? (response.data.records ?  (response.data.records.student ? response.data.records.student.email : null) : null) : null)
                            let school_id = (response.data ? (response.data.records ?  (response.data.records.student ? response.data.records.student.school_id : null) : null) : null)
                            if(email){
                                localStorage.setItem('email' , email)
                            }
                            if(school_id){
                                localStorage.setItem('school_id' , school_id)
                            }
                                const res = await login(email, "123456" , school_id);
                                console.log(res , 'res')
                                setAuthToken(res.data.token);
                                store.dispatch(initIO(res.data.token));
                                localStorage.setItem('token', res.data.token);
                                localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));

                        }
                        // if(response.data.records){
                        //     if(response.data.records.student){
                        //         const res = await login("20thfloortechnologies@gmail.com", "123456" , 'q3kUe3ESNVM3hOkgSbkQ31ucfpOLKcto');
                        //         console.log(res , 'res')
                        //         setAuthToken(res.data.token);
                        //         store.dispatch(initIO(res.data.token));
                        //         localStorage.setItem('token', res.data.token);
                        //         localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
                        //         // window.location=redirectURL;
                        //     }
                        // }
                    }
                }
                catch(e){
                    window.location = redirectUrl
                }
                }



            }
        }

        if (token) {
            setAuthToken(token);
            store.dispatch(initIO(token));
        }
    */

    //  OLD TOKEN CHECKS END

    localStorage.setItem('app', 'SRH Chat v'+process.env.REACT_APP_VERSION);
    let token = localStorage.getItem("token");
    let laravel_token = localStorage.getItem("laravel_access_token");
    let user = localStorage.getItem("user_profile") ? JSON.parse(localStorage.getItem("user_profile")) : {};

    const state = {
      version: "1.0.0",
      entryPath: window.location.pathname,
      laravel_token: laravel_token,
      token: token,
      user: user,
      rooms: [],
      searchResults: [],
      favorites: [],
      meetings: [],
      nav: "colleagues",
      search: "",
      over: null,
      isPicker: false,
      messages: [],
      streams: [],
      inCall: false,
      block: false,
      blocked: [],
      video: true,
      audio: true,
      audioStream: null,
      videoStream: null,
      screenStream: null,
      callStatus: null,
      counterpart: null,
      callDirection: null,
      meeting: null,
      showPanel: true,
      panel: "standard",
      newGroupUsers: [],
      selectedsearch: null,
      people: null,
      inputPlaceHolder: null,
      lastDate: "",
      UserBlocking: false,
      activeStarCheck:false,

      chatBlockByme:false,
      chatBlockByuser:false,

      GropListLoader:true,

      forwardMessageId:null,
      user_profile:null,
      forwardMessageModal:false,

      UpdateProfile:[],

      ImgeLightBox:null,

      replyPupUp:false,
      replyDetails:[],

      activeChatBoder:'',

    };

    setGlobal(state).then(() => 
    console.log('Global state init complete!', state)
    );
}

export default init;
