import Config from "../config";
import React from "react";

const Picture = ({ user = {}, group = false, picture, title = "Group" }) => {
    if (group) {
        if (picture)
            return <img src={`${Config.url || ''}/api/images/${picture.shieldedID}/256`} alt="Picture" className="picture" />;
        else
            return <div className="img">{title.substr(0, 1).toUpperCase()}</div>;
    }

    let firstName = user.firstName || user.first_name || "User";
    let lastName = user.lastName || user.last_name || "Name";
    if (user.picture) {
        return <img src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256`} alt="Picture" className="picture" />;
    }
    else if (user.logo) {
        return <img src={user.logo} alt="Picture" className="picture" />;
    }
    else if (user.photo) {
        return <img src={`${Config.home_url || ''}/${user.photo}`} alt="Picture" />;
    }
    else {
        return <div className="img" style={{ width: "50px", height: "50px" }}>{firstName.substr(0, 1).toUpperCase()}{lastName.substr(0, 1).toUpperCase()}</div>;
    }
};

export default Picture;
