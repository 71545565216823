import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "../assets/Dashboard.css"
import Div100vh from "react-div-100vh";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Welcome from "../features/Welcome";
import Conversation from "../features/Conversation";
import { setGlobal, useGlobal } from "reactn";
import { useSelector } from "react-redux";
import CreateGroup from "../features/Group/Create";
import CreateGroup2 from "../features/Group/Create2";
import Panel from "../features/Panel";
import laravel_axios from "../axios";
import logo from "../assets/logo.png";
import { Spinner } from "reactstrap";
import setAuthToken from "../actions/setAuthToken";
import store from "../store";
import initIO from "../actions/initIO";
import axios from "axios"
import config from '../config';
import Admin from "../features/Admin";
import Meeting from "../features/Meeting";
import Details from "../features/Details";
import NotFound from "../features/NotFound";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


import alertImage from '../assets/imges/undraw_Maintenance_re_59vn.svg'

const Chat = () => {
    const MySwal = withReactContent(Swal)

    const location = useLocation();

    const { room } = useSelector(state => ({ room: state.io.room }));
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Verifying Profile");
    const io = useSelector(state => state.io.io);
    const [over, setOver] = useGlobal('over');
    const [user_profile, setUser_profile] = useGlobal('user_profile');
    const showPanel = useGlobal('showPanel')[0];
    const showDetails = useGlobal('showDetails')[0];
    const panel = useGlobal('panel')[0];

    const callIncrement = useSelector(state => state.rtc.callIncrement);
    const callData = useSelector(state => state.rtc.callData);

    const history = useHistory();

    const getPanel = () => {
        switch (panel) {
            case 'createGroup':
                return <CreateGroup />
            case 'createGroup2':
                return <CreateGroup2 />
            default:
                return <Panel />
        }
    }
    useEffect(() => {
        if (!callData) return;
        setGlobal({
            audio: true,
            video: false,
            callDirection: 'incoming',
            meeting: { _id: callData.meetingID },
        }).then(() => {
            history.replace('/meeting/' + callData.meetingID);
        });
    }, [callIncrement, callData])
    useEffect(() => {
        // await fetchProfile();
        fetchNodeProfile();
        // console.log("Chat - useEffect - io",io)
    }, []);
    useEffect(() => {
    }, [room])
    // const fetchProfile = async() => {
    //     // console.log("Fetch Profile")
    //     let role = localStorage.getItem('role');

    //     let url = role === 'professor' ? '/tutor/get-profile' : '/get-student-profile'
    //     await laravel_axios
    //         .get(url)
    //         .then((res) => {
    //             // console.log("Fetch Profile - Profile", res);
    //             setLoading(false);
    //             // console.log("Loading Chat Page")
    //         })
    //         .catch((err) => {
    //             setLoadingMessage("Network Error")
    //             // console.log("Fetch Notifications - Response - Error", err);
    //         }).finally(function () {
    //     });
    // };
    const fetchNodeProfile = () => {
        // console.log("Fetch Node Profile")
        let user = JSON.parse(localStorage.getItem('user_profile'));
        let role = localStorage.getItem('role');
        let user_id = localStorage.getItem('user_id');
        // let data = {email:"hashimlokasher@gmail.com",password: "11223344"}
        let data = { laravel_user_id: user_id, role: role, user: user }
        axios
            .post(config.url + "/api/login", data)
            .then((res) => {
                // console.log("Fetch Profile - Profile", res);
                // alert( res.data.uuid)
                if (res.data.token && res.data.uuid) {
                    setLoading(false);
                    // console.log("Loading Chat Page")

                    //  TODO Add Node Authentication functionality
                    // console.log("Fetch Node Profile")
                    //  Get Node Token
                    var node_token = res.data.token;
                    // var uuid = res.data.uuid;
                    // var node_token="TOKEN";
                    setAuthToken(node_token);
                    store.dispatch(initIO(node_token));
                    localStorage.setItem('token', node_token);
                    localStorage.setItem('uuid', res.data.uuid);
                    // var laravel_profile = JSON.parse(localStorage.getItem('user_profile'))
                    // laravel_profile.uuid = uuid;
                    // console.log(res.data.user_profile)
                    localStorage.setItem('user_profile', JSON.stringify(res.data.user_profile))
                    setUser_profile(res.data.user_profile)
                } else {
                    console.log("Res.data", res.data.message)
                    window.location.href = config.logout_url + role;
                    // setLoading(true);
                    // if(res.data.user_id){
                    // setLoadingMessage(res.data.message)
                    // }
                }
            })
            .catch((err) => {
                Swal.fire({
                    imageAlt: "Error",
                    imageHeight: "100px",
                    imageUrl: alertImage,
                    // icon: 'error',
                    title: 'Oops...',
                    text: `Sorry, we're doing some updating on the chat. We're coming soon. Thank for your cooperation.`,
                })

                setLoadingMessage("Maintenance Break")
                console.log("Fetch Node Profile - Response - Error", err);
            }).finally(function () {
            });
    };
    return (
        <Div100vh>
            {loading ?
                <div className="account-wrap signin" id="authorize">
                    <div className="logo">
                        <a href="#">
                            <img src={logo} alt="" />
                        </a>
                    </div>
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-sm-7 offset-sm-5">
                                <div className="account-form-wrap form-signup">
                                    <div className="account-title">
                                        <h3>Login Process</h3>
                                    </div>
                                    <div className="btn btn-round btn-secondary py-1 loading-section">
                                        Please Wait! We are processing your request...
                                    </div>
                                    <div className="loading-area">
                                        <p className="font-weight-bolder text-success">{loadingMessage}</p>
                                        <Spinner></Spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="app">
                    {showPanel && getPanel()}
                    <div
                        className={`main uk-flex uk-flex-column aaa ${over ? ' over' : ''}${over === false ? ' exit' : ''}`}>

                        <Switch>
                            <Route path="/admin" component={Admin} />
                            <Route path="/meeting/:id" component={Meeting} />
                            <Route path="/room/:id" component={Conversation} />
                            <Route path="/room/:id/info" component={Details} />
                            <Route exact path="/" component={Welcome} />
                            {/*<Route path="/" component={NotFound}/> /!* Comment this line when Electron build *!/*/}
                            {/* <Route path="/" component={Welcome} />  Uncomment this line when Electron build */}
                        </Switch>
                        {/*<Route path="/" component={NotFound}/> Comment this line when Electron build*/}
                        {/*<Route path="/" component={Welcome}/> Uncomment this line when Electron build*/}
                    </div>
                    {/* {!location.pathname.endsWith('/info') && (showDetails || !location.pathname.startsWith('/meeting')) && <Details/>} */}
                </div>}
        </Div100vh>
    );
}
/*class Chat extends React.Component {
    fetchProfile = () => {
        console.log("Fetch Profile")
        let role = localStorage.getItem('role');

        let url = role === 'professor' ? '/tutor/get-profile' : '/get-school-profile'
        axios
            .get(url)
            .then((res) => {
                console.log("Fetch Profile - Profile", res);
            })
            .catch((err) => {
                console.log("Fetch Notifications - Response - Error", err);
            });
    };
    componentDidMount() {
        this.fetchProfile()
    }
    render() {
        console.log("Dashboard Component")
        return (
            <React.Fragment>
                <section className="dashboard">
                    <h1>Chat Page</h1>
                </section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to="/authorize?log_out=true" className="btn btn-primary">Logout</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}*/

export default Chat;