import axios from "axios";
import Config from "../config";

const getFriendsList = (id, searched) => {
  return axios({
    method: "post",
    url: (Config.url || "") + "/api/message/forward/users",
  });
};

export default getFriendsList;
