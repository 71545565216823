import React, {useState} from 'react';
import "./Room.sass";
import {FiPlus, FiMessageSquare} from 'react-icons/fi';
import { BsThreeDotsVertical } from "react-icons/bs";
import Picture from "../../../components/ChatPicture";
import createRoom from "../../../actions/createRoom";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import Actions from "../../../constants/Actions";
import {useGlobal} from "reactn";
import imge1 from "../../../assets/imges/icons/schoolimge1.png";
import imge2 from "../../../assets/imges/icons/dropDownIcon2.png";
import imge3 from "../../../assets/imges/icons/dropDownIcon3.png";
import imge4 from "../../../assets/imges/icons/Layer 242.png";
import imge5 from "../../../assets/imges/icons/Layer 163 copy 9.png";

const Room = ({user}) => {
    const [hover, setHover] = useState(false);
    const [nav, setNav] = useGlobal('nav');
     const [over, setover] = useGlobal("over");

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const title = `${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)} ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`.substr(0, 22);
    const role = user.user_type;
    // console.log(user,role)

    const chat = async () => {
        const res = await createRoom(user._id);

        if(res.data.room.friendStatus==="Accepted"){
            // setNav('my_friends');
        }
        // setNav('new_requests');
          setover(true);
        const target = `/room/${res.data.room._id}`;
        dispatch({type: Actions.SET_ROOM, room: res.data.room});
        dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
        if (location.pathname !== target) history.replace(target);
    };
    return (
      <div
        className="room uk-flex allUserList-wrap"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={chat}
      >
        <div className="profile">
          <Picture user={user} />
        </div>
        <div className="text">
          <div className="title">
            {title}
            {title.length > 22 && "..."}
          </div>
          <div >
          <div>
          {role == "schooladmin" ? (
            <span className="userStatuschat a">
              <span>
                <img src={imge1} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>School Admin</span>
            </span>
          ) : (
            ""
          )}
          {role == "student" ? (
            <span className="userStatuschat b">
              <span>
                <img src={imge2} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>Student</span>
            </span>
          ) : (
            ""
          )}
          {role == "professor" ? (
            <span className="userStatuschat c">
              <span>
                <img src={imge3} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>Tutor</span>
            </span>
          ) : (
            ""
          )}
        </div>
            <span className="userStatuschat b" style={{display:"none"}}>
              <span>
                <img src={imge2} alt="Imge" />
              </span>
              <span> Student</span>
            </span>
          </div>
        </div>
        {/* <div className="controls" hidden={hover}>
                <div className="date">@{user.username}</div>
            </div>
            <div className="controls" hidden={!hover}>
                <div className="button">
                    <FiMessageSquare/>
                </div>
            </div> */}
        <div className="controls" style={{display:"none"}}>
          <div className="button useinfodropDownContainer">
            <span>
              <BsThreeDotsVertical />
            </span>
            <div className="userinfodorpdown-Wrap">
              <div>
                <div>
                  <img src={imge2} />
                  No of Students (100)
                </div>
                <div>
                  <img src={imge4} /> No of School Admin (20)
                </div>
                <div>
                  <img src={imge5} /> No of Schools
                </div>
                <div>
                  <img src={imge3} />
                  No of Tutors (10)
                </div>
              </div>
            </div>
            {/* <FiMessageSquare/> */}
          </div>
        </div>
      </div>
    );
}

export default Room;
