import React from 'react';
import {useGlobal} from "reactn";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import Config from "../../config";
import logo from "../../assets/imges/logo.png";
import { FaBell } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import SettingOptions from "../../features/Conversation/components/SettingOptions";
const Welcome = () => {
    const user = useGlobal('user')[0];
    const setOver = useGlobal('over')[1];


  const [UpdateProfile, setUpdateProfile] = useGlobal("UpdateProfile");

  const UpdateProfileLocal = JSON.parse(
    localStorage.getItem("updateProfileData")
  );



    const back = () => setOver(false);

    return (
      <div className="content uk-flex uk-flex-column">
        <div className="applogoContainer">
          <div>
            <img src={logo} />
          </div>
          <div>
            <div className="noti-info-wraper">
              <div>
                <div className="notifi-wrap d-none">
                  <span className="notiCount">10+</span>
                  <FaBell />
                  Notifications
                </div>
              </div>
              <div>
                <div className="setting-wrap-top d-none">
                  <div>
                    <FiSettings />
                    Privacy Settings
                  </div>
                  <div className="setting-DropDown">
                    <div>
                      <SettingOptions text="Share My Information" />
                      <SettingOptions text="Message Share" />
                      <SettingOptions text="Email Notifications" />
                      <SettingOptions text="System Notifications" />
                      <SettingOptions text="Others" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <TopBar back={back}/> */}
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          <div className="welcome uk-flex uk-flex-bottom textTransform">
            {UpdateProfile.length == 0
              ? UpdateProfileLocal.userName
              : UpdateProfile.userName}
          </div>
          <div className="profile">
            {/*<Picture user={user}/>*/}
            {UpdateProfile.length == 0 ? (
              <img
                src={`${Config.srhchat || ""}/${
                  UpdateProfileLocal.profilePicture
                }`}
                alt="Picture"
                className="picture"
              />
            ) : (
              <img
                src={`${Config.srhchat || ""}/${UpdateProfile.profilePicture}`}
                alt="Picture"
                className="picture"
              />
            )}
          </div>
          {/*                 <div className="tutorial uk-flex uk-flex-top uk-flex-column uk-flex-center uk-flex-middle">
                    Search for someone to start a conversation,<br/>
                    Add contacts to your favorites to reach them faster
                </div>*/}
          <div className="tutorial uk-flex uk-flex-top uk-flex-column uk-flex-center uk-flex-middle">
            Use left panel to start a conversation
          </div>
        </div>
        <BottomBar />
      </div>
    );
}

export default Welcome;
