import jwtDecode from "jwt-decode";
import { useGlobal } from "reactn";
import IO from "socket.io-client";

import Config from "../config";
import Actions from "../constants/Actions";
import store from "../store";
import getRooms from "./getRooms";
import getNewRequests from "./getNewRequests";
import getGroups from "./getGroups";
import getMyFriends from "./getMyFriends";
import messageSound from "../assets/message.mp3";
import { setGlobal } from "reactn";
const socketPromise = require('../lib/socket.io-promise').promise;





const initIO = token => dispatch => {




    // const user = jwtDecode(token);
    const user = localStorage.getItem('user_profile');
    let io = IO((Config.url || '') + '/');
    io.request = socketPromise(io);

    io.on('connect', () => {
        io.emit('authenticate', { token });
        // console.log('IO connected');
    });

    io.on("blockedCheckUsuer", async (data) => {
        console.log(data)
        let uudid = localStorage.getItem("carentActiveUser");
        let profileId = JSON.parse(localStorage.getItem("user_profile"));
        let profileIdUUID = profileId._id;
        console.log("profileId", profileIdUUID);
        let list = data.blocked
        let chatid = data.roomID

        if (data.user_id !== profileIdUUID) {
            // console.log("list", list)
            // console.log("chatid", chatid)
            let check = list.includes(chatid);
            // console.log("check ", check)
            // let ayyy
            if (chatid == uudid) {
                await setGlobal({
                    chatBlockByuser: check,
                });
            }
        }





        // console.log("UserBlocking",UserBlocking)

        // console.log("sokeite clicked rehman")
        // console.log('IO connected');
    });

    io.on('authenticated', () => {
        // console.log('IO authenticated');
        dispatch({ type: Actions.IO_INIT, io });
    });


    io.on('logOutChat', () => {
        console.log("logoout")
        let role = localStorage.getItem("role");
        localStorage.clear();
        if(role=='professor'){
            window.location = Config.redirecttutor;
        }else{
            window.location = Config.redirectstudent;
        }
    });

    // updating node profile
    io.on("UpdateProfile", async (data) => {
        // let profileId = JSON.parse(localStorage.getItem("user_profile"));
        // let profileIdUUID = profileId.uuid;
        console.log("data ==", data)
        let updatedProfile = {
            userName: data.user.fullName,
            schoolName: data.user.school_name,
            profilePicture: data.user.photo,
        };

        localStorage.setItem('updateProfileData', JSON.stringify(updatedProfile));
        console.log()
        await setGlobal({
            UpdateProfile: updatedProfile,
        });
    });


    io.on('message-in', data => {
        console.log(data)
        const { room, message } = data;

        const currentRoom = store.getState().io.room;

        const audio = document.createElement('audio');
        audio.style.display = "none";
        audio.src = messageSound;
        audio.autoplay = true;
        audio.onended = () => audio.remove();
        document.body.appendChild(audio);

        if (!currentRoom || currentRoom._id !== room._id) {
            store.dispatch({ type: Actions.MESSAGES_ADD_ROOM_UNREAD, roomID: room._id });
        }

        if (!currentRoom) return;
        if (currentRoom._id === room._id) store.dispatch({ type: Actions.MESSAGE, message });

        // getRooms().then(res => store.dispatch({ type: Actions.SET_ROOMS, rooms: res.data.rooms })).catch(err => console.log(err));
        // getGroups().then(res => store.dispatch({ type: Actions.SET_GROUPS, groups: res.data.rooms })).catch(err => console.log(err));
        // getMyFriends().then(res => store.dispatch({ type: Actions.SET_MY_FRIENDS, rooms: res.data.rooms })).catch(err => console.log(err));
        // getNewRequests().then(res => store.dispatch({ type: Actions.SET_NEW_REQUESTS, rooms: res.data.rooms })).catch(err => console.log(err));
    })

    io.on('emoji-in', data => {
        let currentRoom = localStorage.getItem("carentActiveUser");
        if (currentRoom == data.room._id) {
            store.dispatch({ type: Actions.SET_MESSAGES, messages: data.messages });
        }
    })

    io.on('friend-in', data => {
        store.dispatch({ type: Actions.SET_ROOM, room: data.room })
    })

    io.on('subject-in', data => {
        getGroups().then(res => store.dispatch({ type: Actions.SET_GROUPS, groups: res.data.rooms })).catch(err => console.log(err));
    })

    io.on('remove-message', data => {
        let currentRoom = localStorage.getItem("carentActiveUser");
        if (currentRoom == data.room._id) {
            store.dispatch({ type: Actions.SET_MESSAGES, messages: data.messages });
        }
    })

    io.on('newProducer', data => {
        // console.log('newProducer', data)
        if (data.socketID !== io.id) store.dispatch({ type: Actions.RTC_PRODUCER, data })
    });

    io.on('leave', data => {
        // console.log('leave', data);
        let producers = store.getState().rtc.producers;
        producers = producers.filter(producer => producer.socketID !== data.socketID);
        // console.log('producers after leave', producers);
        store.dispatch({ type: Actions.RTC_RESET_PRODUCERS, producers, socketID: data.socketID })
    });

    io.on('consumers', data => {
        // console.log('consumers', data);
        store.dispatch({ type: Actions.RTC_CONSUMERS, consumers: data });
    });

    io.on('newPeer', data => {
        // console.log('newPeer', data);
        store.dispatch({ type: Actions.RTC_NEW_PEER, data });
    });

    io.on('call', data => {
        // console.log('call', data);
        store.dispatch({ type: Actions.RTC_SET_COUNTERPART, counterpart: data.counterpart });
        store.dispatch({ type: Actions.RTC_CALL, data });
    });

    io.on('close', data => {
        // console.log('close', data);
        store.dispatch({ type: Actions.RTC_CLOSE, data });
    });

    io.on('answer', data => {
        // console.log('answer', data);
        store.dispatch({ type: Actions.RTC_ANSWER, data });
    });

    io.on('remove', data => {
        // console.log('remove', data.producerID);
        let producers = store.getState().rtc.producers;
        producers = producers.filter(producer => producer.producerID !== data.producerID);
        // console.log('producers after remove', producers);
        store.dispatch({ type: Actions.RTC_RESET_PRODUCERS, producers, socketID: data.socketID, lastLeaveType: 'remove', producerID: data.producerID })
    });

    io.on('onlineUsers', data => {
        store.dispatch({ type: Actions.ONLINE_USERS, data });
    });

    io.on('refresh-meetings', data => {
        store.dispatch({ type: Actions.REFRESH_MEETINGS, timestamp: data.timestamp });
    });

    io.on('user-deleted', async () => {
        localStorage.removeItem('token');
        await setGlobal({
            token: null,
            user: {},
        });
    })

    io.on('typing', data => {
        console.log(data)
        if (store.getState().io.room && data.roomID === store.getState().io.room._id) {
            if (!store.getState().messages.typing){
                if (data.isTyping) {
                    dispatch({ type: Actions.SET_TYPING, typing: data.isTyping });
                    setTimeout(() => {
                        dispatch({ type: Actions.SET_TYPING, typing: false });
                    }, 10000);
                }
            }
         
           
        }
    })

    io.on('disconnected', () => {

    });

    window.onbeforeunload = function () {
        io.emit('leave', { socketID: io.id, roomID: store.getState().rtc.roomID });
        return;
    }
};

export default initIO;
