import axios from "axios";
import Config from "../config";

const toggleGroup = (roomID,Status) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/group/join",
        data: { roomID }
    });
};

export default toggleGroup;
