import React, { useState } from "react";
import "./Room.sass";
import { useGlobal } from "reactn";
import { FiPhone, FiMoreHorizontal } from "react-icons/fi";
import Picture from "../../../components/Picture";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import getMeetingRoom from "../../../actions/getMeetingRoom";
import { useDispatch, useSelector } from "react-redux";
import postCall from "../../../actions/postCall";
import Actions from "../../../constants/Actions";

import dubleUnTicks from "../../../assets/imges/icons/Layer 160 copy 3.png";

import imge1 from "../../../assets/imges/icons/schoolimge1.png";
import imge2 from "../../../assets/imges/icons/dropDownIcon2.png";
import imge3 from "../../../assets/imges/icons/dropDownIcon3.png";

const MyFriend = ({ room }) => {
  // console.log(room ,"room")
  const roomsWithNewMessages = useSelector(
    (state) => state.messages.roomsWithNewMessages
  );
  const onlineUsers = useSelector((state) => state.io.onlineUsers);
  const [hover, setHover] = useState(false);
  const user = useGlobal("user")[0];
  const [userProfile, setuserProfile] = useState(
    JSON.parse(localStorage.getItem("user_profile"))
  );
  const setAudio = useGlobal("audio")[1];
  const setVideo = useGlobal("video")[1];
  const setCallDirection = useGlobal("callDirection")[1];
  const setMeeting = useGlobal("meeting")[1];

  const [over, setover] = useGlobal("over");

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  let other = {};
  room.people.forEach((person) => {
    if (userProfile.user_id != person.user_id) other = person;
  });
  // console.log("other: ",other)
  if (!other.firstName) {
    other = { ...other, firstName: "Deleted", lastName: "User" };
  }

  const title = (room.isGroup
    ? room.title
    : `${other.firstName.charAt(0).toUpperCase() + other.firstName.slice(1)} ${other.lastName.charAt(0).toUpperCase() + other.lastName.slice(1)}`
  ).substr(0, 22);
  const role = room.isGroup ? room.user_type : `${other.user_type}`;

  let lastMessage = room.lastMessage;
  let text = "";

  if (!lastMessage && room.isGroup) text = "New group created.";
  if (!lastMessage && !room.isGroup)
    text = `No messages with ${other.firstName} yet.`;

  if (!lastMessage) lastMessage = {};

  if (lastMessage.author === userProfile._id && !room.isGroup) text += "You: ";

  switch (lastMessage.type) {
    case "file":
      text += "Sent a file.";
      break;
    case "image":
      text += "Sent a picture.";
      break;
    default:
      text += lastMessage.content || "";
  }

  const date = lastMessage ? moment(lastMessage.date).format("MM/DD/YY") : "";
  const time = lastMessage ? moment(lastMessage.date).format("h:mm A") : "";

  const notAvailable = (name) => {
    addToast(`The ${name} feature is not available yet.`, {
      appearance: "warning",
      autoDismiss: true,
    });
  };

  const width = window.innerWidth;
  const isMobile = width < 700;

  const warningToast = (content) => {
    addToast(content, {
      appearance: "warning",
      autoDismiss: true,
    });
  };

  const errorToast = (content) => {
    addToast(content, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const call = async (callee, isVideo) => {
    if (
      onlineUsers.filter((u) => u.id === other.user_id).length === 0 &&
      !room.isGroup
    )
      return warningToast("Can't call user because user is offline");
    await setAudio(true);
    await setVideo(isVideo);
    await setCallDirection("outgoing");
    dispatch({ type: Actions.RTC_SET_COUNTERPART, counterpart: callee });
    try {
      const res = await getMeetingRoom({
        startedAsCall: true,
        caller: userProfile.user_id,
        callee: other.user_id,
        callToGroup: room.isGroup,
        group: room._id,
      });
      await setMeeting(res.data);
      history.replace("/meeting/" + res.data._id);
      await postCall({ roomID: room._id, meetingID: res.data._id });
    } catch (e) {
      errorToast("Server error. Unable to initiate call.");
    }
  };

  const getStatus = () => {
    if (room.isGroup) return null;
    if (
      onlineUsers.filter((u) => u.id === other.user_id && u.status === "busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === other.user_id && u.status === "online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === other.user_id && u.status === "away")
        .length > 0
    )
      return "away";
    return null;
  };

  return (
    <div
      className="room uk-flex roomsContainer"
      onMouseOver={!isMobile ? () => setHover(true) : undefined}
      onMouseOut={!isMobile ? () => setHover(false) : undefined}
      onClick={() => {
        setover(true)
        const target = `/room/${room._id}`;
        dispatch({type:Actions.SET_ROOM,room})
        if (location.pathname !== target) history.replace(target);
      }}
    >
      <div className="uk-flex uk-flex-middle" style={{ position: "relative" }}>
        {roomsWithNewMessages.includes(room._id) ? (
          <span className="msgCount">
            {roomsWithNewMessages.filter((r) => room._id === r).length}
          </span>
        ) : (
          ""
        )}
        <div className="profile">
          <Picture
            user={other}
            group={room.isGroup}
            picture={room.picture}
            title={room.title}
          />
        </div>
        {getStatus() ? (
          <div className={`dot ${getStatus()}`} />
        ) : (
          <div className={`dot ${getStatus()}`} />
        )}
      </div>
      <div className="text">
        <div
          className={`title${
            roomsWithNewMessages.includes(room._id) ? " highlight" : ""
          }`}
        >
          {title.substr(0, 20)}
          {title.length > 20 && "..."}
        </div>
        <div
          className={`message${
            roomsWithNewMessages.includes(room._id) ? " highlight" : ""
          }`}
        >
          {text.substr(0, 25)}
          {text.length > 25 && "..."}
        </div>
        <div>
          {role == "schooladmin" ? (
            <span className="userStatuschat a">
              <span>
                <img src={imge1} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>School Admin</span>
            </span>
          ) : (
            ""
          )}
          {role == "student" ? (
            <span className="userStatuschat b">
              <span>
                <img src={imge2} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>Student</span>
            </span>
          ) : (
            ""
          )}
          {role == "professor" ? (
            <span className="userStatuschat c">
              <span>
                <img src={imge3} alt="Imge" />
              </span>
              {/* <span>{role}</span> */}
              <span>Tutor</span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="controls">
        <div className="date">
          <div className="sidetickDate">
            <span>
              {date}
              <br></br>
              <br></br>
              <br></br>
            </span>
            <span>{/* <img src={dubleUnTicks} alt="Picture" /> */}</span>
          </div>
        </div>
      </div>
      {/* <div className="controls" hidden={!hover}>
                <div className="button" onClick={() => call(other, false)}>
                    <FiPhone/>
                </div>
                <div className="uk-inline">
                    <div className="button" type="button">
                        <FiMoreHorizontal/>
                    </div>
                    <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar">
                        <div className="link" onClick={() => notAvailable('Remove Conversation')}>Remove</div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default MyFriend;
