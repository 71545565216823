import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux"
import { useLocation } from 'react-router-dom'
import config from '../config';
import logo from "../assets/logo.png"
import "../assets/Authorize.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { Spinner } from "reactstrap";
import { useGlobal } from 'reactn';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

function Authorize(props) {
    // console.log("Authorize Component", props)
    const [message, setMessage] = useState('Authenticating...');
    const [userProfile, setUserProfile] = useState({});
    const dispatch = useDispatch();
    let query = useQuery();
    let access_token = query.get("access_token");
    let role = query.get("role");
    let user_id = query.get("user_id");
    let log_out = query.get('log_out')
    function fetchProfile() {
        // Fetch user profile and store in Redux
        setMessage("Fetching Profile")
        // console.log("Fetch Profile Function - Inside Start");
        const uninterceptedAxiosInstance = axios.create(
            { baseURL: config.base_url }
        );

        let url = role === 'professor' ? 'tutor/get-profile' : 'get-student-profile'
        uninterceptedAxiosInstance.get(url, {
            headers: {
                "X-Authorization": "Bearer " + access_token
            }
        })
            .then((result) => {
                setMessage("Storing User Profile");
                // console.log("Storing User Profile",result);
                let user_profile = "";
                if (role === 'professor') {
                    user_profile = result.data.records.profile
                } else {
                    user_profile = result.data.records.student
                }

                let updatedProfile = {
                    userName: user_profile.name,
                    schoolName: user_profile.school_name,
                    profilePicture: user_profile.photo,
                };
                localStorage.setItem('updateProfileData', JSON.stringify(updatedProfile));
                // localStorage.setItem('user_profile', JSON.stringify(user_profile));
                localStorage.setItem('user_id', user_id);
                setMessage('Authenticated!');
                setMessage("Redirecting to Chat now!");
                // console.log("Fetch Profile Function - Inside End");
                // history.push('/')
                // alert("last logout then")
                window.location.replace('/');
            })
            .catch((error) => {
                console.log("Error", error) //Please Authenticate or whatever returned from server
                if (error.message === "Network Error") {
                    setMessage("Network Error");
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        setMessage('Not Authenticated!');
                        setMessage("Redirecting to Login now!");
                        // alert("last logout catch")
                        //redirect to login
                        logout();
                    }
                }
            });
    }

    function logout() {
        console.log("Logout Function")
        let role = localStorage.getItem('role')
        let access_token = localStorage.getItem('laravel_access_token')
        let logout_role = role ? role : 'schooladmin';

        if (access_token) {
            let url = role === 'professor' ? '/tutor/logout' : '/logout'
            const uninterceptedAxiosInstance = axios.create(
                { baseURL: config.base_url }
            );
            console.log("Logout - Access Token", access_token)
            uninterceptedAxiosInstance.post(url, {
                headers: {
                    "X-Authorization": "Bearer " + access_token
                }
            }).then(
                (result) => {
                    console.log("Logout - Response", result.data);
                    if (result.data._metadata.outcomeCode === 200) {

                        console.log("Removing LocalStorage")
                        localStorage.removeItem('user_profile');
                        localStorage.removeItem('laravel_access_token');
                        localStorage.removeItem('role');
                        console.log("Logged out");

                        //  Redirect to Auth
                        // window.location.href = config.logout_url + role;
                    }
                }
            ).catch((error) => {
                console.log("Found an Error.", error);
            });
            console.log("Removing LocalStorage")
            localStorage.removeItem('user_profile');
            localStorage.removeItem('laravel_access_token');
            localStorage.removeItem('role');
            console.log("Logged out");

            //  Redirect to Auth
            window.location.href = config.logout_url + role;
        } else {
            console.log("Logout - No Access Token")
            localStorage.removeItem('user_profile');
            localStorage.removeItem('laravel_access_token');
            localStorage.removeItem('role');
            console.log("Logged out");

            //  Redirect to Auth
            window.location.href = config.logout_url + role;
        }
    }

    function logout_old() {
        let role = localStorage.getItem('role')
        let logout_role = role ? role : 'schooladmin';
        localStorage.removeItem('user_profile');
        localStorage.removeItem('laravel_access_token');
        localStorage.removeItem('role');
        console.log("Logged out", config.logout_url + logout_role);
        window.location.href = config.logout_url + role;
        //  Redirect to Auth
    }

    function login(access_token, role) {
        localStorage.setItem('laravel_access_token', access_token);
        localStorage.setItem('role', role)
        // console.log("Logged in", access_token);
        //  Redirect to /
    }

    useEffect(() => {
        console.log("Authorize - useEffect")
        if (access_token && role) {
            // console.log("Access Token && Role Start")
            setMessage("Authenticating");
            login(access_token, role);
            fetchProfile();
            console.log("Access Token && Role End")
        } else if (log_out) {
            console.log("Authorize - useEffect - Else If - Log_out")
            setMessage("Logging Out");
            logout();
            setMessage("Redirecting to Login");
        } else {
            console.log("Authorize - useEffect - Else")
            setMessage("Logging Out");
            logout();
            setMessage("Redirecting to Login");
        }
    }, []);

    return (
        <React.Fragment>
            <div className="account-wrap signin" id="authorize">
                <div className="logo">
                    <a href="#">
                        <img src={logo} alt="" />
                    </a>
                </div>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-sm-7 offset-sm-5">
                            <div className="account-form-wrap form-signup">
                                <div className="account-title">
                                    <h3>Login Process</h3>
                                </div>
                                <div className="btn btn-round btn-secondary py-1 loading-section">
                                    Please Wait! We are processing your request...
                                </div>
                                <div className="loading-area">
                                    <p className="font-weight-bolder text-success">{message}</p>
                                    <Spinner></Spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Authorize
