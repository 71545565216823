import axios from "axios";
import Config from "../config";

const getProfile = (id) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/user/fetch_profile",
        data: { id }
    });
};

export default getProfile;
