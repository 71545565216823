import React, { useState } from "react";
import { BiLink } from "react-icons/bi";
import { FaTimes, FaRegFilePdf } from "react-icons/fa";
import { ImFileZip, ImFileWord } from "react-icons/im";
import { AiFillCaretDown } from "react-icons/ai";

import img1 from "../../../assets/imges/gallery/galary.png";
import img2 from "../../../assets/imges/gallery/galary2.png";
import img3 from "../../../assets/imges/gallery/galary3.png";
import img4 from "../../../assets/imges/gallery/galary4.png";
import img5 from "../../../assets/imges/gallery/galary5.png";
import config from '../../../config'
import { useSelector, useDispatch } from "react-redux";


import { Lightbox } from "react-modal-image";


let MediaContainer = (props) => {
      const [open, setOpen] = useState(null);
      let MediaFileList = useSelector((state) => state.io.messages);
      let ImagesList = MediaFileList.filter((data)=>{
        return data.type == "image";
      })
      let FileList = MediaFileList.filter((data)=>{
        return data.type == "file";
      })

  let [showFilter, setshowFilter] = useState(false);
  let [filterItem, setfilterItem] = useState("All Photos, Files and Links");

  let showfilter = () => {
    setshowFilter(!showFilter);
  };
  let getExt = (text)=>{
          let exta = text.split('.').pop(); ;
          return exta
  }
  return (
    <div className="side-room-share-det">
      <div className="">
        <div className="side-filder-container">
          <div className="close-chat-details">
            <span className="closechat-icon" onClick={props.showMediaFiles}>
              <FaTimes />
            </span>
          </div>
          {/* <pre> {JSON.stringify(ImagesList, null, 2)}</pre> */}
          <div className="search-filter-media-dorop">
            <div className="searchContainer-dropdown" onClick={showfilter}>
              <span>{filterItem}</span>
              <span className="show-dorpDownas-container">
                {showFilter ? <FaTimes /> : <AiFillCaretDown />}
              </span>
            </div>
            <div
              className={
                showFilter
                  ? "drop-down-container-list"
                  : "drop-down-container-list hide"
              }
            >
              <span
                className={
                  filterItem == "All Photos, Files and Links" ? "active" : ""
                }
                onClick={() => {
                  setfilterItem("All Photos, Files and Links");
                  setshowFilter(false);
                }}
              >
                All Photos and Files
              </span>
              <span
                className={filterItem == "Photos" ? "active" : ""}
                onClick={() => {
                  setfilterItem("Photos");
                  setshowFilter(false);
                }}
              >
                Photos
              </span>
              <span
                className={filterItem == "Files" ? "active" : ""}
                onClick={() => {
                  setfilterItem("Files");
                  setshowFilter(false);
                }}
              >
                Files
              </span>
              <span
                className={filterItem == "Links" ? "active d-none" : "d-none"}
                onClick={() => {
                  setfilterItem("Links");
                  setshowFilter(false);
                }}
              >
                Links
              </span>
            </div>
          </div>
          <div
            className={
              filterItem == "Photos" ||
              filterItem == "All Photos, Files and Links"
                ? "gallery-container"
                : "gallery-container d-none"
            }
          >
            <div className="imgesLightBox ">
              {open && (
                <Lightbox
                  medium={`${config.url || ""}/api/images/${open}/1024`}
                  large={`${config.url || ""}/api/images/${open}/2048`}
                  alt=""
                  showRotate={true}
                  // hideDownload={true}
                  onClose={() => setOpen(null)}
                />
              )}
            </div>

            <div className="gallery-pictures">
              {ImagesList.map((data) => {
                return (
                  <>
                    <div>
                      <img
                        onClick={() =>
                          setOpen(data.content)
                        }
                        className="showmedeaContainer"
                        src={config.url + "/api/images/" + data.content}
                        alt="P"
                      />
                    </div>
                  </>
                );
              })}

              <small>
                {" "}
                {ImagesList.length == 0 ? "No Photo Found" : null}{" "}
              </small>

              {/* <div className="see-more-gallary">
                <div>
                  <span>+17</span>
                </div>
                <div>more photos</div>
              </div> */}
            </div>
          </div>
          <div
            className={
              filterItem == "Files" ||
              filterItem == "All Photos, Files and Links"
                ? "shared-links"
                : "shared-links d-none"
            }
          >
            <div>
              <h2>Shared Files</h2>
            </div>
            <div className="links-container">
              {FileList.map((data) => {
                return (
                  <span className="links-elements">
                    <a
                      href={config.url + "/api/files/" + data.content}
                      download
                      targe="_blank"
                    >
                      {/* <span
                        className={`fileshere 
                        ${getExt(data.file.name) == "zip" ? "zip" : "null"} 
                        ${getExt(data.file.name) == "docx" ? "doc" : "null"} 
                        ${getExt(data.file.name) == "docx" ? "doc" : "null"} 
                        `}
                      >
                        <FaRegFilePdf />
                      </span> */}
                      {/* file ext */}
                      {getExt(data.file.name) == "docx" ? (
                        <span className="fileshere doc">
                          <ImFileWord />
                        </span>
                      ) : null}
                      {getExt(data.file.name) == "zip" ? (
                        <span className="fileshere zip">
                          <ImFileZip />
                        </span>
                      ) : null}
                      {getExt(data.file.name) == "pdf" ? (
                        <span className="fileshere pdf">
                          <FaRegFilePdf />
                        </span>
                      ) : null}
                      {/* file ext */}
                      <small>{data.file.name}</small>
                    </a>
                  </span>
                );
              })}
              <small> {FileList.length == 0 ? "No Shared Files" : null}</small>
            </div>
            <div className="more-link-container d-none">
              <a href="#" className="show-more-links">
                View All Files
              </a>
            </div>
          </div>
          <div
            className={
              filterItem == "Links" ||
              filterItem == "All Photos, Files and Links"
                ? "shared-links d-none"
                : "shared-links d-none"
            }
          >
            <div>
              <h2>Shared Links</h2>
            </div>
            <div className="links-container">
              <span className="links-elements">
                <a href="#" className="icon-links">
                  <BiLink />
                  <small>https://ourwork.20thfloor.us</small>
                </a>
              </span>
              <span className="links-elements">
                <a href="#" className="icon-links">
                  <BiLink />
                  <small>https://ivylabtech.com</small>
                </a>
              </span>
              <span className="links-elements">
                <a href="#" className="icon-links">
                  <BiLink />
                  <small>http://chat-beta.20thfloor.com</small>
                </a>
              </span>
            </div>
            <div className="more-link-container">
              <a href="#" className="show-more-links">
                View All Links
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MediaContainer;
