import React, { useEffect, useState } from 'react';
import "./TopBar.sass";
import { FiMoreHorizontal, FiExternalLink, FiSettings, FiHome, FiPlusCircle, FiCpu } from 'react-icons/fi';
import Picture from "../../../components/Picture";
import { useGlobal } from "reactn";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import getMeetingRoom from "../../../actions/getMeetingRoom";
import getProfile from "../../../actions/getProfile";
import { useSelector } from "react-redux";
import Config from "../../../config";
import { Modal } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { SiGooglescholar } from "react-icons/si";
import { FiUser } from "react-icons/fi";
import { ImPhone, ImLocation2, ImFilePicture } from "react-icons/im";
import { FaRegCalendarAlt, FaRegBuilding, FaFlag } from "react-icons/fa";
import BtnLoader from '../../Conversation/components/BlockerLoader'
const TopBar = () => {
  const baseURL = "https://beta.schoolroomhelp.com";
  const signinURL = baseURL + "/signin";
  const studentDashboardURL = baseURL + "/student-dashboard";
  const schoolDashboardURL = baseURL + "/school-dashboard";

  const onlineUsers = useSelector(state => state.io.onlineUsers);
  const io = useSelector(state => state.io.io);
  const [nav, setNav] = useGlobal('nav');
  const setToken = useGlobal('token')[1];
  const setPanel = useGlobal('panel')[1];
  const setOver = useGlobal('over')[1];
  const [user, setUser] = useGlobal('user');
  const setAudio = useGlobal('audio')[1];
  const setVideo = useGlobal('video')[1];
  const setCallDirection = useGlobal('callDirection')[1];
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();


  const [UpdateProfile, setUpdateProfile] = useGlobal("UpdateProfile");


  const UpdateProfileLocal = JSON.parse(
    localStorage.getItem("updateProfileData")
  );
  var user_profile = JSON.parse(
    localStorage.getItem("user_profile")
  );
  // console.log(user_profile)
  const [ProfileModalCon, setProfileModalCon] = useState(false);

  //  setUpdateProfile(UpdateProfilelocal);

  const logout = async () => {
    let role = user.user_type;
    io.disconnect();
    const username = user.username;
    localStorage.removeItem('token');
    await setToken(null);
    await setUser({});
    addToast(`User ${username} logged out!`, {
      appearance: 'success',
      autoDismiss: true,
    })
    if (role) {
      if (role == "Student") {
        console.log("Role:Student");
        window.location = studentDashboardURL;
      } else if (role == "School Admin") {
        console.log("Role:Admin");
        window.location = schoolDashboardURL;
      }
    } else {
      window.location = signinURL;
    }
  };

  const errorToast = content => {
    addToast(content, {
      appearance: 'error',
      autoDismiss: true,
    })
  };

  const [user_profileApi, setuser_profileApi] = useState([])

  const fetchProfile = async () => {
    let id = user_profile._id
    setProfileModalCon(true)
    await getProfile(id).then(res=>{
      console.log(res.data)
      setuser_profileApi(res.data)
    }).catch(error=>{
      console.log(error)
    });
    // if(res.data){
    //   user_profile = res.data;
    // }
  };

  const newMeeting = async () => {
    await setAudio(true);
    await setVideo(true);
    await setCallDirection('meeting');
    try {
      const res = await getMeetingRoom();
      history.replace('/meeting/' + res.data._id);
    }
    catch (e) {
      errorToast('Server error. Unable to initiate call.');
    }
  };

  const getStatus = () => {
    if (onlineUsers.filter(u => u.id === user.id && u.status === 'busy').length > 0) return 'busy';
    if (onlineUsers.filter(u => u.id === user.id && u.status === 'online').length > 0) return 'online';
    if (onlineUsers.filter(u => u.id === user.id && u.status === 'away').length > 0) return 'away';
    return null;
  };

  useEffect(() => {
    // console.log("useEffect - TopBar",user)
  })
  return (
    <>
      <div className="top-bar uk-flex uk-flex-between uk-flex-middle topBarComputerUmer">
        <div className="uk-flex uk-flex-middle">
          <div
            className="profile"
            onClick={() => {
              setOver(true);
              setNav("rooms");
              history.replace("/");
            }}
          >
            {/* {}
            {UpdateProfile.photo == "" ? (
              <Picture user={UpdateProfile.photo || {}} />
            ) : (
              <Picture user={UpdateProfile.photo || {}} />
            )}
            <img src={} /> */}

            {UpdateProfile.length == 0 ? (
              <img
                src={`${Config.srhchat || ""}/${UpdateProfileLocal.profilePicture
                  }`}
                alt="Picture"
                className="picture"
              />
            ) : (
              <img
                src={`${Config.srhchat || ""}/${UpdateProfile.profilePicture}`}
                alt="Picture"
                className="picture"
              />
            )}
          </div>
          {getStatus() && <div className={`dot ${getStatus()}`} />}
        </div>
        <div className="topUserInfoBar">
          {/* {JSON.stringify(UpdateProfile, null, 2)} */}
          <h4>
            {UpdateProfile.length == 0
              ? UpdateProfileLocal.userName
              : UpdateProfile.userName}
          </h4>
          <p>
            {UpdateProfile.length == 0
              ? UpdateProfileLocal.schoolName
              : UpdateProfile.schoolName}
          </p>
        </div>
        <div className="nav" style={{ alignItems: "center" }}>
          {(user.level === "root" || user.level === "admin") && (
            <div
              className={`button${location.pathname.startsWith("/admin") ? " active" : ""
                }`}
              onClick={() => {
                setOver(true);
                history.replace("/admin");
              }}
            >
              <FiCpu />
            </div>
          )}
          {/* <div
            className="button mobile"
            onClick={() => {
              setOver(true);
              history.replace("/");
            }}
          >
            <FiHome />
          </div> */}
          {/*                     <div className="button" onClick={() => setPanel('createGroup')}>
                        <FiPlusCircle/>
                    </div> */}
          {/* <div className={`button${nav === 'settings' ? ' active' : ''}`} onClick={() => {
                        setNav('settings');
                    }}>
                        <FiSettings/>
                    </div> */}
          <div className="uk-inline userdot-wrap">
            <div className="button info-dots" onClick={() => fetchProfile()} type="button">
              <FiMoreHorizontal />
            </div>
            <div className="userInfoConaterLive">
              <div>
                <div>Active</div>
                <div>Away</div>
                <div>Dot not distrub</div>
              </div>
            </div>

            {/* <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar d-none"> */}
            {/* <div className="link" onClick={() => newMeeting()}>New Meeting</div> */}
            {/* <div className="link" onClick={() => setPanel('createGroup')}>New Group</div> */}
            {/* {Config.demo && <div className="divider"/>} */}
            {/* {Config.demo && <div className="link" onClick={honeyside}>Honeyside <div className="icon"><FiExternalLink/></div></div>} */}
            {/* {Config.demo && <div className="link" onClick={codeCanyon}>CodeCanyon <div className="icon"><FiExternalLink/></div></div>} */}
            {/* {(user.level === 'root' || user.level === 'admin') && <div className="divider"/>} */}
            {/* {(user.level === 'root' || user.level === 'admin') && <div className="link" onClick={() => {
                                setOver(true);
                                history.replace('/admin');
                            }}>Admin Panel</div>} */}
            {/* <div className="divider"/> */}
            {/* <Link to="/authorize?log_out=true" className="Link">Logout</Link> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* profileModal */}
      <Modal
        show={ProfileModalCon}
        onHide={() => setProfileModalCon(false)}
        centered
        className="profileModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
         {/* <pre> {JSON.stringify(user_profileApi.length,null,2)}</pre> */}
          <div className="user-profile-container" id="user-profile-container">
            <div className="profile-container">
             
              {user_profileApi.length == 0 &&  <BtnLoader/>}
              {user_profileApi.length !== 0 && (

                     <div className="porfile-wrap">
                        {/* <pre> {JSON.stringify(user_profileApi,null,2)}</pre> */}
                <div className="profile-imge">
                  <img src={user_profileApi && user_profileApi.photo ? Config.srhchat + user_profileApi.photo : "#"} alt="P" />
                  <span className="status-dot waiting"></span>
                </div>
                {/* <pre>{JSON.stringify(user_profileApi, null, 2)}</pre> */}
                <div className="user-details-container">
                  <div>
                    <h4> {user_profileApi.fullName}  </h4>
                  </div>
                  <div className="porfile-user-list">
                    <span>First Name</span>
                    <span>
                      <FiUser /> {user_profileApi.firstName}
                    </span>
                  </div>
                  <div className="porfile-user-list">
                    <span>Last Name</span>
                    <span>
                      <FiUser /> {user_profileApi.lastName }
                    </span>
                  </div>
                  <div className="porfile-user-list">
                    <span>Email</span>
                    <span className="emailOverflow">
                      <AiOutlineMail /> {user_profileApi.email}
                    </span>
                  </div>
                  <div className={`porfile-user-list ${user_profileApi.phone ? "" : "d-none"}`}>
                    <span>Contact</span>
                    <span>
                      <ImPhone />
                      {user_profileApi.phone}
                    </span>
                  </div>
                  <div className="porfile-user-list">
                    <span>DOB</span>
                    <span>
                      <FaRegCalendarAlt /> {user_profileApi.dob}
                    </span>
                  </div>

                  <div className="porfile-user-list">
                    <span>Role</span>
                    <span>
                      <div className="profile-details-iner">
                        <div>
                          <SiGooglescholar />
                        </div>
                        <div>{user_profileApi.user_type == "student" ? "Student" : user_profileApi.user_type == "schooladmin" ? "School Admin" : "Professor"}</div>
                      </div>
                    </span>
                  </div>


                  {/* <div className="porfile-user-list">
                    <span>Gender</span>
                    <span>
                      <FiUser />
                      {user_profileApi.gender}
                    </span>
                  </div> */}
                </div>
                <div className="user-details-container">
                  <div>
                    <h5>Location</h5>
                  </div>
                  <div className="porfile-user-list">
                    <span>Country</span>
                    <span>
                      <ImLocation2 /> {user_profileApi.country}
                    </span>
                  </div>
                  <div className="porfile-user-list">
                    <span>City</span>
                    <span>
                      <FaRegBuilding /> {user_profileApi.city}
                    </span>
                  </div>
                  <div className="porfile-user-list">
                    <span>State</span>
                    <span>
                      <FaFlag /> {user_profileApi.state}
                    </span>
                  </div>
                </div>

              </div> 
             
              ) }
             </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* profileModal */}</>
  );
}

export default TopBar;
