import Config from "../config";
import React from "react";

const Picture = ({ user = {}, room = {}, group = false, picture, title = "Group" }) => {
    // console.log("user",user)
    const error = () => {
        return <div className="img">{title.substr(0, 1).toUpperCase()}</div>;
    }
    if (room) {
        if (room.subjectImage) {
            return <img src={`${Config.home_url || ''}/${room.subjectImage}`} alt="Picture" onError={error} />;
        }
    }
    if (group) {
        if (picture)
            return <img src={`${Config.url || ''}/api/images/${picture.shieldedID}/256`} alt="Picture" className="picture" />;
        else
            return <div className="img">{title.substr(0, 1).toUpperCase()}</div>;
    }
    if (room.subjectImage) {
        return <img src={`${Config.home_url || ''}/${room.subjectImage}`} alt="Picture" />;
    }
    let firstName = user.first_name || "User";
    let lastName = user.last_name || "Name";
    if (user.picture) {
        return <img src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256`} alt="Picture" className="picture" />;
    }
    else if (user.logo) {
        return <img src={user.logo} alt="Picture" className="picture" />;
    }
    /*    else if(user.photo){
            return <img src={Config.home_url+'/'+user.photo} alt="Picture" className="picture" />;
        }*/
    else {
        if (user.photo) {
            return <img src={`${Config.home_url || ''}/${user.photo}`} alt="Picture" />;
        } else {
            return <div className="img">{firstName.substr(0, 1).toUpperCase()}{lastName.substr(0, 1).toUpperCase()}</div>;
        }
    }
};

export default Picture;
