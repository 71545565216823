import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import "./Conversation.sass";
import { useParams } from "react-router-dom";
import getRoom from "../../actions/getRoom";
import getFriendsList from "../../actions/getFriendsLIst";
import ClipLoader from "react-spinners/ClipLoader";
import Messages from "./components/Messages";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../constants/Actions";

import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import MediaContainer from './components/MediaContainer';
import { useToasts } from "react-toast-notifications";

import { Modal } from 'react-bootstrap';

import ForwardMessage from "../../actions/forwardMessage";
//  import io from "socket.io-client";

//  import Config from "../../config";

//   let socket = io((Config.url || "") + "/");
import imge1 from "../../assets/imges/icons/schoolimge1.png";
import imge2 from "../../assets/imges/icons/dropDownIcon2.png";
import imge3 from "../../assets/imges/icons/dropDownIcon3.png";
import imge4 from "../../assets/imges/icons/icon4.png";

import Cofig from '../../config'
import Picture from "../../components/ChatPicture";
import { ImCross } from 'react-icons/im'
import { BsSearch } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import { FiSettings, FiSend } from "react-icons/fi";
import logo from "../../assets/imges/logo.png";
import SettingOptions from "./components/SettingOptions";

import FarwardLoader from '../Conversation/components/BlockerLoader'

import MsgPlaceholder from './components/SmsLoading'
import FarwardLoading from '../Panel/ChatPlaceHolder'
import { Lightbox } from "react-modal-image";

import config from "../../config";
import LoginProfile from "../../actions/LoginProfile";
import { RiCompassDiscoverLine } from "react-icons/ri";

const Conversation = () => {
  // console.log("sokcet ", socket);
  const { addToast } = useToasts();
  const [chatBlockByuser, setchatBlockByuser] = useGlobal("chatBlockByuser");
  const [chatBlockByme, setchatBlockByme] = useGlobal("chatBlockByme");
  // console.log("UserBlockedbyme", UserBlockedbyme);
  //  const [blocked, setblocked] = useGlobal("blocked");
  const room = useSelector((state) => state.io.room);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfile, setuserProfile] = useState(
    JSON.parse(localStorage.getItem("user_profile"))
  );
  // const [user_profile, setUser_profile] = useGlobal('user_profile'); 
  const [ProfileSettings, setProfileSettings] = useState(userProfile.settings.profile_permission); 
  
  // const [UserBlockedbyme, setUserBlockedbyme] = useState(false);
  // const [UserBlocked, setUserBlocked] = useGlobal("UserBlocking");

  const setOver = useGlobal("over")[1];
  const user = useGlobal("user")[0];
  
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const back = () => {
    setOver(false);
    history.replace("/");
  };

  const [MediaFiles, setMediaFiles] = useState(false);  

    const [replyPupUp, setreplyPupUp] = useGlobal('replyPupUp');



  useEffect(() => {

    
    //
    // console.log("use eeffect");

    //  socket.on("blockedCheckUsuer", (data) => {
    //    console.log("data", data);
    //    // console.log('IO connected');
    //  });

    //
    setreplyPupUp(false)

    setchatBlockByme(false);
    setchatBlockByuser(false);
    setLoading(true);
    setMediaFiles(false);
    // console.log("room geting id === ", id);

    getRoom(id)
      .then((res) => {
        dispatch({ type: Actions.SET_ROOM, room: res.data.room });
        dispatch({
          type: Actions.SET_MESSAGES,
          messages: res.data.room.messages,
        });
        setLoading(false);
        setError(false);
        dispatch({ type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: id });

        localStorage.setItem("carentActiveUser", id);

        if (res.data.room.isGroup == true) {
          setchatBlockByuser(false);
          setchatBlockByme(false);
        } else {
          // console.log("room data ", res.data.room.people[0].blocked);

          // ********************
          let other, me;
          // console.log("user", user);

          if (!res.data.room.isGroup && res.data.room.people) {
            res.data.room.people.forEach((person) => {
              // console.log();
              if (person._id !== userProfile._id) other = person;
              if (person._id == userProfile._id) me = person;
            });
          }

          // console.log("me ", me);
          // console.log("other", other);

          //  return

          // ********************
          let blockedList = other.blocked;
          let blockedCheck = blockedList.includes(id);
          // console.log("cehck", blockedCheck);
          let myblockLIst = me.blocked;
          let userblockedbyme = myblockLIst.includes(id);

          if (blockedCheck) {
            setchatBlockByuser(true);
            // setUserBlocking(true);
          } else {
            setchatBlockByuser(false);
            // setUserBlocking(false);
          }
          //  myCheck
          if (userblockedbyme) {
            setchatBlockByme(true);
          } else {
            setchatBlockByme(false);
          }
        }
      })
      .catch((err) => {
        dispatch({ type: Actions.SET_ROOM, room: null });
        dispatch({ type: Actions.SET_MESSAGES, messages: [] });
        setLoading(false);
        if (!err.response || err.response.status !== 404) setError(true);
      });
  }, [setLoading, id]);



  // **************************************

  const [loadFriendList, setloadFriendList] = useState(false)
  // const getListofFrinds=()=>{
  //   setloadFriendList(true)

  // }

  const [forwardModal, setforwardModal] = useGlobal("forwardMessageModal");
  const [friendsList, setfriendsList] = useState([]);
  const [friendsListBackup, setfriendsListBackup] = useState([]);
  useEffect(() => {
    // setloadFriendList(true);
    // setTimeout(() => {
    //   setloadFriendList(false);
    // }, 2000);
    setloadFriendList(true);
    getFriendsList().then((res) => {
      // console.log(res)
      setfriendsList(res.data)
      setfriendsListBackup(res.data)
      setloadFriendList(false);
    }).catch((error) => {
      console.log(error)
      //  setloadFriendList(false);
    })
  }, [forwardModal]);



  // **************************************
  const filterFriends = (value) => {
    if (value == "") {
      setfriendsList(friendsListBackup);
    } else {
      const filterList = friendsListBackup.filter((data) => {
        if (data.isGroup) {
          if (data.title.toLowerCase().includes(value.toLowerCase()))
            return true;
        } else {
          let list = data.people.filter((dataa) => {
            if (dataa._id != userProfile._id) {
              if (dataa.fullName.toLowerCase().includes(value.toLowerCase())) {
                return true;
              }
            }
          });
          if (list.length > 0) {
            return true;
          }
        }
      });
      setfriendsList(filterList);
    }


  }
  // **************************************


  const [forwardMessageId, setforwardMessageId] = useGlobal("forwardMessageId");
  const colleaguesList = useSelector((state) => state.io.colleagues);
  const groupsList = useSelector((state) => state.io.groups);

  const [SelectedForwardList, setSelectedForwardList] = useState([])
  const [farwardSmsLoader, setfarwardSmsLoader] = useState(false);

  const sendFarwoardSMS = async () => {
    console.log(SelectedForwardList);
    // alert( JSON.stringify(SelectedForwardList,null,2));
    // return
    // setforwardModal(true);
    //  const target = `/room/${SelectedForwardList[0].id}`;
    //  if (location.pathname !== target) history.push(target);
    // return
    setfarwardSmsLoader(true)
    let data = { messageID: forwardMessageId, to_id: SelectedForwardList };
    const res = await ForwardMessage(data);
    if (res) {
      // ***************
      const target = `/room/${SelectedForwardList[0].id}`;
      if (location.pathname == target) {
        getRoom(room._id).then((res) => {
          dispatch({ type: Actions.SET_ROOM, room: res.data.room });
          dispatch({
            type: Actions.SET_MESSAGES,
            messages: res.data.room.messages,
          });
        }).catch(e => {
          setfarwardSmsLoader(false);
          console.log("error while sending sms ");
        });
      } else {
        history.push(target);
      }
      // history.push(`/room/${SelectedForwardList[0].id}`);
      setforwardModal(false)
      setfarwardSmsLoader(false);
      setSelectedForwardList([])
      // ***************
    }
  };

  const [ImgeLightBox, setImgeLightBox] = useGlobal("ImgeLightBox");



  const UpdateForwardList = (id, img) => {
    console.log("sended id is ", id)
    let removeifFound = null;
    let filterCheck = SelectedForwardList.filter((data, index) => {
      console.log("loop id ", data.id)
      if (data.id == id) {
        removeifFound = index;
        console.log("index found ", removeifFound);
        return true;
      } else {
        console.log("index found false ", removeifFound);
        return false
      }
    });
    console.log("arry ", filterCheck);
    console.log("removeifFound ", removeifFound);

    let selected = { id: id, photo: img };
    setSelectedForwardList([...SelectedForwardList, selected]);

    if (filterCheck.length > 0) {
      console.log("removeing element found eeeeeeeeeeeeeee");
      SelectedForwardList.splice(removeifFound, 1);
      setSelectedForwardList([...SelectedForwardList]);
    }



    //  if (filterCheck.length !== null) {
    //    console.log("index found ", removeifFound);
    //    console.log("index found arr ", removeifFound);

    //  } else {
    //    console.log("index item ssss", removeifFound);
    //    let selected = { id: id, photo: img };
    //    setSelectedForwardList([...SelectedForwardList, selected]);
    //  }



  }

  const removeFarwardItem = (e) => {
    SelectedForwardList.splice(e, 1);
    setSelectedForwardList([...SelectedForwardList]);
  }



  const Loading = () => (
    <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <ClipLoader size={60} color={"#666"} loading={loading} />
    </div>
  );
  const AnimatedLoading = () => (
    <div className="content chatAnimatedLoaders">
      <div>
        <ReactPlaceholder
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={4}
        />
      </div>
      <div className="right">
        <ReactPlaceholder
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={4}
        />
      </div>
      <div>
        <ReactPlaceholder
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={4}
        />
      </div>
      <div className="right">
        <ReactPlaceholder
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={4}
        />
      </div>
    </div>
  );

  const NotFound = () => (
    <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <div className="notfound">Chat Not Found</div>
      <div className="notfound-extended">
        This chat does not exist.
        <br />
        This is probably a broken URL.
      </div>
    </div>
  );

  const Error = () => {
    history.replace("/");
    // addToast(`Error.`, {
    //   appearance: "warning",
    //   autoDismiss: true,
    // });
    return (
      <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <div className="notfound">Network Error</div>
        <div className="notfound-extended">Could not reach server.</div>
      </div>
    );
  };

  const Content = () => <Messages />;

  const showMediaFiles = () => {
    setMediaFiles(!MediaFiles);
  };

  return (
    <div
      className={`content uk-flex uk-flex-column uk-flex-between chatContainerWrapper ${
        MediaFiles ? null : "unactive"
      }`}
    >
      <div className="applogoContainer">
        <div>
          <img src={logo} />
        </div>
        <div>
          <div className="noti-info-wraper">
            <div>
              <div className="notifi-wrap d-none">
                <span className="notiCount">10+</span>
                <FaBell />
                Notifications
              </div>
            </div>
            <div>
              <div className="setting-wrap-top">
                <div>
                  <FiSettings />
                  Privacy Settings
                </div>
                <div className="setting-DropDown">
                <div>
                      <SettingOptions
                        ProfileSettings={ProfileSettings}
                        text="Share My Information"
                      />
                      {/* <SettingOptions text="Message Share" />
                    <SettingOptions text="Email Notifications" />
                    <SettingOptions text="System Notifications" />
                    <SettingOptions text="Others" /> */}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!loading && (
        <TopBar back={back} loading={loading} showMediaFiles={showMediaFiles} />
      )}

      {/* {loading && <Loading />} */}
      {loading && <MsgPlaceholder />}
      {error && <Error />}
      {!room && !loading && !error && <NotFound />}

      {room && !loading && <Content />}

      {chatBlockByme && !chatBlockByuser && (
        <div className="sendSms-Wraper">
          <hr className="m-0"></hr>
          <div className="bottom-bar-conversation text-danger justify-content-lg-center uk-flex uk-flex-middle sendSmsContainer">
            You Blocked the user
          </div>
        </div>
      )}

      {chatBlockByuser && (
        <div className="sendSms-Wraper">
          <hr className="m-0"></hr>
          <div className="bottom-bar-conversation text-danger justify-content-lg-center uk-flex uk-flex-middle sendSmsContainer">
            Your are blocked by user
          </div>
        </div>
      )}

      {!chatBlockByme && !chatBlockByuser && <BottomBar loading={loading} />}
      {/* {!block && <BottomBar loading={loading} />} */}
      {/* **************************** */}
      <div className="imgesLightBox ">
        {ImgeLightBox && (
          <Lightbox
            medium={`${config.url || ""}/api/images/${ImgeLightBox}/1024`}
            large={`${config.url || ""}/api/images/${ImgeLightBox}/2048`}
            alt=""
            showRotate={true}
            // hideDownload={true}
            onClose={() => setImgeLightBox(null)}
          />
        )}
      </div>
      {/* **************************** */}
      <div className={`mediaContainer ${MediaFiles ? "active" : null}`}>
        <MediaContainer showMediaFiles={showMediaFiles} />
      </div>
      <Modal
        show={forwardModal}
        onHide={() => {
          setforwardModal(false);
        }}
        backdrop="static"
        keyboard={false}
        scrollable
        className="farwardModal"
        onEntered={() => {
          setSelectedForwardList([]);
          // getListofFrinds();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="farwardSelectedImges">
              {SelectedForwardList.map((data, index) => {
                return (
                  <div className={`${data.photo == "" ? "border-0" : null}`}>
                    {data.photo == "" ? (
                      <Picture user={data.photo} />
                    ) : (
                      <img src={Cofig.srhchat + "/" + data.photo} />
                    )}
                    {/* <img src="https://schoolroomhelp.20thfloor.com/public/uploads/26/James Cross/Photo/SRH Logo.jpg" /> */}
                    <div
                      onClick={() => {
                        removeFarwardItem(index);
                      }}
                    >
                      <ImCross />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="farwardSearch">
              <div>
                <BsSearch />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => filterFriends(e.target.value)}
                  placeholder="Search..."
                />
              </div>
            </div>
          </Modal.Title>
          {/* <Modal.Title>Id : {forwardMessageId}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="p-0">
          {loadFriendList && <FarwardLoading />}
          {!loadFriendList && (
            <div>
              {/* /********************** */}
              {friendsList.map((data, index) => {
                var roomId = data._id;
                // console.log("data colleagues", data);
                // return
                if (!data.isGroup) {
                  let list = data.people.map((dataa) => {
                    if (dataa._id != userProfile._id) {
                      return (
                        <div
                          className="room uk-flex allUserList-wrap"
                          onClick={() => {
                            UpdateForwardList(roomId, dataa.photo);
                          }}
                        >
                          <div className="profile">
                            {dataa.photo == "" ? (
                              <Picture user={dataa.photo} />
                            ) : (
                              <img src={Cofig.srhchat + "/" + dataa.photo} />
                            )}
                          </div>
                          <div className="text">
                            <div className="title">
                              {dataa.fullName}
                              {dataa.fullName.length > 22 && "..."}
                            </div>
                            <div>
                              <div>
                                {dataa.user_type == "schooladmin" ? (
                                  <span className="userStatuschat a">
                                    <span>
                                      <img src={imge1} alt="Imge" />
                                    </span>

                                    <span>School Admin</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {dataa.user_type == "student" ? (
                                  <span className="userStatuschat b">
                                    <span>
                                      <img src={imge2} alt="Imge" />
                                    </span>

                                    <span>Student</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {dataa.user_type == "professor" ? (
                                  <span className="userStatuschat c">
                                    <span>
                                      <img src={imge3} alt="Imge" />
                                    </span>

                                    <span>Tutor</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  });

                  return list;
                } else {
                  return (
                    <div
                      className="room uk-flex allUserList-wrap"
                      onClick={() => {
                        UpdateForwardList(roomId, data.subjectImage);
                      }}
                    >
                      <div className="profile">
                        {data.photo == "" ? (
                          <Picture user={data.subjectImage} />
                        ) : (
                          <img src={Cofig.srhchat + "/" + data.subjectImage} />
                        )}
                      </div>
                      <div className="text">
                        <div className="title">
                          {data.title}
                          {data.title.length > 22 && "..."}
                        </div>
                        <div>
                          <div>
                            <span className="userStatuschat a">
                              <span>
                                <img src={imge4} alt="Imge" />
                              </span>

                              <span>Group</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {/* **********************  */}

              {/* **********************  */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              {SelectedForwardList.length > 0
                ? `Selected items (${SelectedForwardList.length})`
                : ""}
            </div>
            <div>
              {farwardSmsLoader ? (
                <FarwardLoader />
              ) : SelectedForwardList.length > 0 ? (
                <div
                  className="button farwardbtn sendsmsbtn"
                  onClick={() => {
                    sendFarwoardSMS();
                  }}
                >
                  <FiSend />
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Conversation;
