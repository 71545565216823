import React from 'react';
let JoinGropLoader = ()=>{
      return (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
}
export default JoinGropLoader;