import axios from "axios";
import Config from "../config";

const toggleBlock = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/block/toggle",
        data: { data }
    });
};

export default toggleBlock;
