import React, { useState } from "react";
import moment from "moment";
import "./Message.sass";
import emojiRegex from "emoji-regex/text";
import Config from "../../../config";
import { useGlobal } from "reactn";
import ReactImageAppear from "react-image-appear";

import { AiOutlineDownload, AiOutlineStar } from "react-icons/ai";
import { MdTagFaces } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FcDocument } from "react-icons/fc";
import { FaReply } from "react-icons/fa";
import striptags from "striptags";

import imoje1 from "../../../assets/imges/icons/animated/icon1.gif";
import imoje2 from "../../../assets/imges/icons/animated/icon2.gif";
import imoje3 from "../../../assets/imges/icons/animated/icon3.gif";
import imoje4 from "../../../assets/imges/icons/animated/icon4.gif";
import imoje6 from "../../../assets/imges/icons/animated/icon6.gif";
import imoje7 from "../../../assets/imges/icons/animated/icon7.gif";
import studentIcon from "../../../assets/imges/icons/studentIcon.png";
import removeMessage from "../../../actions/removeMessage";

import toggleEmoji from "../../../actions/toggleEmoji";
import getRoom from "../../../actions/getRoom";
import ForwardMessage from "../../../actions/forwardMessage";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../constants/Actions";

import { Lightbox } from "react-modal-image";

import config from "../../../config";
import BlockLoader from "./BlockerLoader";
import {  BsReplyFill } from "react-icons/bs"
const Message = ({ message, previous, next, onOpen, lastDate }) => {
  let { author, content, date } = message;
  const dispatch = useDispatch();
  const room = useSelector((state) => state.io.room);

  const user = useGlobal("user")[0];
  const [userProfile, setuserProfile] = useState(
    JSON.parse(localStorage.getItem("user_profile"))
  );
  const uuid = localStorage.getItem("uuid");
  const [open, setOpen] = useState(null);

    const [replyPupUp, setreplyPupUp] = useGlobal('replyPupUp');
    const [replyDetails, setreplyDetails] = useGlobal('replyDetails');



  const [RemoveMessageLoader, setRemoveMessageLoader] = useState(false)
  const RemoveMessage = async () => {
    setRemoveMessageLoader(true)
    const res = await removeMessage(message._id)
    if (res) {
      getRoom(room._id)
        .then((res) => {
            setRemoveMessageLoader(false);
          dispatch({ type: Actions.SET_ROOM, room: res.data.room });
          dispatch({
            type: Actions.SET_MESSAGES,
            messages: res.data.room.messages,
          });
        });
    }
  }


  const [forwardMessageId, setforwardMessageId] = useGlobal("forwardMessageId");
  const [forwardMessageModal, setforwardMessageModal] = useGlobal("forwardMessageModal");
  const forwardMessage = async () => {
    // let data = { messageID: message._id, to_id: ["60b09819fbb3564102f150cf","60b09833fbb3564102f150d0", "60b09a91fbb3564102f150d4"] }
    // const res = await ForwardMessage(data)
    // if (res) {
    //   getRoom(room._id)
    //     .then((res) => {
    //       dispatch({ type: Actions.SET_ROOM, room: res.data.room });
    //       dispatch({
    //         type: Actions.SET_MESSAGES,
    //         messages: res.data.room.messages,
    //       });
    //     });
    // }
     setforwardMessageId(message._id);
     setforwardMessageModal(true);
     

    // return
    // let data = { messageID: , to_id: "60b09819fbb3564102f150cf" }
    // const res = await ForwardMessage(data)
    // if (res) {
    //   getRoom(room._id)
    //     .then((res) => {
    //       dispatch({ type: Actions.SET_ROOM, room: res.data.room });
    //       dispatch({
    //         type: Actions.SET_MESSAGES,
    //         messages: res.data.room.messages,
    //       });
    //     });
    // }
  }

  const [emojeLoader, setemojeLoader] = useState(false)
  const sendemoji = async (value, url) => {
    setemojeLoader(true)
    let data = { value, url, messageID: message._id }
    const res = await toggleEmoji(data)
    // console.log(res)
    if (res) {
      getRoom(room._id)
        .then((res) => {
          dispatch({ type: Actions.SET_ROOM, room: res.data.room });
          dispatch({
            type: Actions.SET_MESSAGES,
            messages: res.data.room.messages,
          });
            setemojeLoader(false);
        }).catch(error=>{
          setemojeLoader(false);
        });
    }
  }
  // const [lastDate, setlastDate] = useGlobal("lastDate");

  if (!author) author = { firstName: "Deleted", lastName: "User" };
  if (previous && !previous.author)
    previous.author = { firstName: "Deleted", lastName: "User" };
  if (next && !next.author)
    next.author = { firstName: "Deleted", lastName: "User" };

  const isMine = userProfile._id === author._id;



  // *********************
  let attachPrevious = false,
    attachNext = false;

  if (
    previous &&
    Math.abs(moment(previous.date).diff(moment(date), "minutes")) < 3 &&
     previous.type !== "joingroup" && 
    author._id === previous.author._id
  )
  attachPrevious = true;


  if (
    next &&
    Math.abs(moment(next.date).diff(moment(date), "minutes")) < 3 &&  

    author._id === next.author._id
  )
    attachNext = true;

    // **********************

  const Picture = ({ user }) => {
    // if (user.picture)
    if (user.photo)
      return (
        <img src={`${Config.home_url || ""}/${user.photo}`} alt="Picture" />
      );
    else if (user.logo) return <img src={user.logo} alt="Picture" />;
    /*        else if (user.photo) {
                return <img src={Config.home_url + '/' + user.photo} alt="Picture" className="picture"/>;
            }*/ else if (user.firstName && user.lastName)
      return (
        <div className="img">
          {user.firstName.substr(0, 1)}
          {user.lastName.substr(0, 1)}
        </div>
      );
    else if (user.first_name && user.last_name)
      return (
        <div className="img">
          {user.first_name.substr(0, 1)}
          {user.last_name.substr(0, 1)}
        </div>
      );
    else return <div className="img">UN</div>;
  };

  const Details = ({ side }) => {
    // if (!attachNext)
    //   return (
    //     <div className={`message-details ${side}`}>
    //       {moment(date).format("MMM DD - h:mm A")}
    //     </div>
    //   );
    // else return null;
      return (
        <div className={`message-details ${side}`}>
          {moment(date).format("MMM DD - h:mm A")}
        </div>
      );
  };

  const PictureOrSpacer = () => {
    if (attachPrevious) return <div className="spacer" />;
    else
      return (
        <div className="picture chatUerImgeDetails-Container">
          <Picture user={author} />
          {/* {JSON.stringify(author, null, 2)} */}

          {!isMine ? (
            <div className="details-user-wrapper">
              <h4>
                {author.fullName}
                 {/* <AiOutlineStar /> */}
              </h4>
              <div
                className={`${author.user_type == "professor" ? "d-none" : null
                  }`}
              >
                <div>
                  <img
                    className="userStatusImge"
                    src={studentIcon}
                    alt="Imge"
                  />
                </div>
                {author.school_name && (
                  <div>
                    <p>Student ({author.school_name})</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
  };

  const noEmoji = content.replace(emojiRegex(), "");
  const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, "");

  const getBubble = () => {
    if (attachPrevious || isOnlyEmoji)
      if (isMine) return " right";
      else return " left";
    if (isMine) return " bubble-right right";
    else return " bubble-left left";
  };

  const convertUrls = (text) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return `<a href=${url} target="_blank">${url}</a>`;
    });
  };
    //  const [open, setOpen] = useState(null);
    const [ImgeLightBox,setImgeLightBox] = useGlobal("ImgeLightBox");

  const Content = () => {
    switch (message.type) {
      case "image":
        return (
          <>
            <div className="ImgeContainerChat replys">
              <img
                onClick={() => setImgeLightBox(message.content)}
                src={`${Config.url || ""}/api/images/${message.content}/512`}
              />
            </div>
            
          </>
          // <ReactImageAppear
          //   src={`${Config.url || ""}/api/images/${message.content}/512`}
          //   animationDuration="0.2s"
          //   onClick={() => onOpen(message)}
          // />
        );

      case "file":
        return (
          <a
            // target="_blank"
            href={`${Config.url || ""}/api/files/${message.content}`}
            download={message.file ? message.file.name : "File"}
          >
            <div className="content-download">
              <div>
                <FcDocument />
              </div>
              <div className="content-file">
                <div className="content-name">
                  {message.file ? message.file.name : "File"}
                </div>
                <div className="content-size">
                  {message.file
                    ? Math.round((message.file.size / 1024 / 1024) * 10) / 10 +
                    " MB"
                    : "Size"}
                </div>
              </div>
              <div className="content-icon">
                <AiOutlineDownload />
              </div>
            </div>
          </a>
        );
      default:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: convertUrls(
                striptags(content, ["a", "strong", "b", "i", "em", "u", "br"])
              ),
            }}
          />
        );
    }
  };

  const getBubbleClass = () => {
    if (message.type === "image") return "ImgeOuter";
    return isOnlyEmoji ? "emoji-bubble" : "bubble";
  };
  
  if (message.type == "joingroup") {
    return (
      <>
        {lastDate ? (
          <div className="Date-time-element DateHandler"> 
            <span>
              {moment(message.date).format("MMM-DD-Y") ==
              moment(new Date().getTime()).format("MMM-DD-Y")
                ? "Today"
                : moment(message.date).format("MMM-DD-Y") ==
                  moment(new Date().getTime() - 86400000).format("MMM-DD-Y")
                ? "Yesterday"
                : moment(message.date).format("MMM-DD-Y")}
            </span>
          </div>
        ) : null}
        <div className="new-member-join ">
          {/* {JSON.stringify(message, null, 2)} */}
          {message.author.fullName
            ? message.author.fullName
            : message.author.first_name + " " + message.author.last_name} join this group at {moment(message.date).format("MMM DD - h:mm A")}
        </div>
      </>
    );
  }

  return (
    <>
      {lastDate ? (
        <div className="Date-time-element DateHandler"> 
          <span>
            {moment(message.date).format("MMM-DD-Y") ==
            moment(new Date().getTime()).format("MMM-DD-Y")
              ? "Today"
              : moment(message.date).format("MMM-DD-Y") ==
                moment(new Date().getTime() - 86400000).format("MMM-DD-Y")
              ? "Yesterday"
              : moment(message.date).format("MMM-DD-Y")}
          </span>
        </div>
      ) : null}
      <div
        className={`message${isMine ? " right" : " left"}${
          attachPrevious ? " attach-previous" : ""
        }${attachNext ? " attach-next" : ""}`}
      >
        <PictureOrSpacer />
        <div
          className={`content-x${isMine ? " right" : ""}${
            attachPrevious ? " attach-previous" : ""
          }${attachNext ? " attach-next" : ""}  ${message.type=="image" ? "imgReplyContainer":""} `}
        >
          {message.forwarded && message.forwarded && (
            <span className="FarwardSMS">
              <BsReplyFill /> Forwarded
            </span>
          )}

          <div
            className={`${getBubbleClass()}${getBubble()}${
              attachPrevious ? " attach-previous" : ""
            }${attachNext ? " attach-next" : ""} ${message.type=="reply"?"replys":null}`}
          >
            {message && message.type && message.type=="reply"? (  <div className="replySmsTextContainer">
          {message.replyAt.type=="image"?( 
              <div className="ImgeContainerChat">
              <img
                onClick={() => setImgeLightBox(message.replyAt.content)}
                src={`${Config.url || ""}/api/images/${message.replyAt.content}/512`}
              />
            </div>
          ):( <p>{message.replyAt.content}</p>)}
           
          </div>) : null }
       
            <Content />
          </div>
     
        

          <div className={`imojeShowFlex ${isMine ? "right" : "left"}`}>
            <div>
              {/* total: {JSON.stringify(message.emojis.length, null, 2)} */}

              {/* ******************** */}
              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "kiss";
              }).length > 0 ? (
                <div>
                  <img src={imoje1} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "kiss";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
              {/* ******************** */}
              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "laugh";
              }).length > 0 ? (
                <div>
                  <img src={imoje2} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "laugh";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
              {/* ******************** */}

              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "like";
              }).length > 0 ? (
                <div>
                  <img src={imoje3} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "like";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
              {/* ******************** */}
              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "dislike";
              }).length > 0 ? (
                <div>
                  <img src={imoje4} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "dislike";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
              {/* ******************** */}
              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "angry";
              }).length > 0 ? (
                <div>
                  <img src={imoje6} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "angry";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
              {/* ******************** */}
              {message.emojis &&
              message.emojis.filter((data) => {
                return data.emoji == "heart";
              }).length > 0 ? (
                <div>
                  <img src={imoje7} alt="Imge" />
                  <div>
                    {
                      message.emojis.filter((data) => {
                        return data.emoji == "heart";
                      }).length
                    }
                  </div>
                </div>
              ) : null}
              {/* ******************** */}
            </div>
            <div>
              <Details side={isMine ? "right" : "left"} />
            </div>
          </div>
        </div>

        <div>
          <div>
            {!RemoveMessageLoader && emojeLoader ? (
              <div className="mt-1">
                <BlockLoader />
              </div>
            ) : (
              <div className="imjesContainer">
                <MdTagFaces />
                <div className="imojesDropDown">
                  <div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("kiss", imoje1);
                        }}
                        src={imoje1}
                        alt="Imge"
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("laugh", imoje2);
                        }}
                        src={imoje2}
                        alt="Imge"
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("like", imoje3);
                        }}
                        src={imoje3}
                        alt="Imge"
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("dislike", imoje4);
                        }}
                        src={imoje4}
                        alt="Imge"
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("angry", imoje6);
                        }}
                        src={imoje6}
                        alt="Imge"
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          sendemoji("heart", imoje7);
                        }}
                        src={imoje7}
                        alt="Imge"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className='ReplyIcon'>
          <FaReply onClick={()=>{setreplyPupUp(true);setreplyDetails(message)}} />
        </div>
        </div>

        <div>
          {RemoveMessageLoader ? (
            <div className="mt-1">
              <BlockLoader />
            </div>
          ) : (
            <div>
              <div className="imjesContainer">
                <BiDotsVerticalRounded />
                <div
                  className="imojesDropDown justify-content-center"
                  style={{ width: `${isMine ? "140px" : "80px"}` }}
                  // style={{ width: `80px` }}
                >
                  <div>
                    <div
                      onClick={() => {
                        RemoveMessage();
                      }}
                      className={`chatbnts ${isMine ? null : "d-none"}`}
                    >
                      Remove
                    </div>
                    <div
                      onClick={() => {
                        forwardMessage();
                      }}
                      className="chatbnts "
                    >
                      Forward
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Message;
