import axios from "axios";
import Config from "../config";

const toggleFriend = (roomID,Status) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/friend/toggle",
        data: { roomID, Status:Status }
    });
};

export default toggleFriend;
