import React from 'react';
import { useGlobal } from "reactn";
import "./NavBar.sass";
import { FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch } from 'react-icons/fi';

import imge1 from "../../../assets/imges/icons/icon1.png";
import imge2 from "../../../assets/imges/icons/icon2.png";
import imge3 from "../../../assets/imges/icons/icon3.png";
import imge4 from "../../../assets/imges/icons/icon4.png";


import {useDispatch, useSelector} from "react-redux";
import Actions from "../../../constants/Actions";
import getNewRequests from "../../../actions/getNewRequests";
import getMyFriends from "../../../actions/getMyFriends";
import getColleagues from "../../../actions/getColleagues";
import getGroups from "../../../actions/getGroups";

const NavBar = (props) => {
  const [activeStarCheck,setactiveStarCheck] = useGlobal("activeStarCheck");
  const dispatch = useDispatch();
  const [nav, setNav] = useGlobal('nav');
  const [selectedsearch, setselectedsearch] = useGlobal("selectedsearch");
  const [inputPlaceHolder, setinputPlaceHolder] = useGlobal("inputPlaceHolder");

  return (
    <div className="nav-bar uk-flex SideBarImgeWrap">
      <div
        className={`item${nav === "new_requests" ? " active" : ""}`}
        onClick={() => {
          props.activeFavrate(false)
          setNav("new_requests")
          setactiveStarCheck(false)
          setselectedsearch(null)
          setinputPlaceHolder(null)
          getNewRequests().then(res => dispatch({
            type: Actions.SET_NEW_REQUESTS,
            rooms: res.data.rooms
          })).catch(err => console.log(err));
        }}
      >
        <div className="icon">
          {/* <FiMessageCircle/> */}
          <img src={imge1} alt="Picture" />
        </div>
        <div className="text toltipsContainer">My Conversations</div>

      </div>
      <div
        className={`item${nav === "my_friends" ? " active" : ""}`}
        onClick={() => {
          setactiveStarCheck(false)
          setNav("my_friends")
           props.activeFavrate(false);
          setselectedsearch("colleagues")
          setinputPlaceHolder("Search My School Students")
          getMyFriends("colleagues",null).then(res => dispatch({
            type: Actions.SET_MY_FRIENDS,
            rooms: res.data.rooms
          })).catch(err => console.log(err));
        }}
      >
        <div className="icon">
          {/* <FiSearch/> */}
          <img src={imge2} alt="Picture" style={{ width: "100%" }} />
        </div>
        <div className="text toltipsContainer">My Friends</div>
      </div>
      <div
        className={`item${nav === "colleagues" ? " active" : ""}`}
        onClick={() => {
          setNav("colleagues");
          setactiveStarCheck(false)
           props.activeFavrate(false);
          setselectedsearch("colleagues")
          setinputPlaceHolder("Search My School Students")
          getColleagues("colleagues",null).then(res => dispatch({
            type: Actions.SET_COLLEAGUES,
            users: res.data.users
          })).catch(err => console.log(err));

        }}
      >
        <div className="icon">
          {/* <FiStar /> */}
          <img src={imge3} alt="Picture" />
        </div>
        <div className="text toltipsContainer">Find People</div>
      </div>
      <div
        className={`item${nav === "groups" ? " active" : ""}`}
        onClick={() => {
          setNav("groups")
          setactiveStarCheck(false)
          setselectedsearch(null)
           props.activeFavrate(false);
          setinputPlaceHolder("Search Subjects")
          getGroups().then(res => dispatch({
            type: Actions.SET_GROUPS,
            groups: res.data.rooms
          })).catch(err => console.log(err));
        }}
      >
        <div className="icon">
          {/* <FiUsers/> */}
          <img src={imge4} alt="Picture" />
        </div>
        <div className="text toltipsContainer">Subject Chat Room</div>
      </div>
    </div>
  );
}

export default NavBar;
