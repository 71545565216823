const Actions = {
    IO_INIT: 'IO_INIT',
    SET_MESSAGES: 'SET_MESSAGES',
    MORE_MESSAGES: 'MORE_MESSAGES',
    MESSAGE: 'MESSAGE',
    SET_ROOM: 'SET_ROOM',
    SET_ROOMS: 'SET_ROOMS',

    SET_NEW_REQUESTS: 'SET_NEW_REQUESTS',
    SET_MY_FRIENDS: 'SET_MY_FRIENDS',
    SET_COLLEAGUES: 'SET_COLLEAGUES',
    SET_GROUPS: 'SET_GROUPS',
    SET_FAVOURITES: 'SET_FAVOURITES',
    ONLINE_USERS: 'ONLINE_USERS',

    SET_EMOJI: 'SET_EMOJIS',
    SET_TYPING: 'SET_TYPING',

    RTC_PRODUCER: 'RTC_PRODUCER',
    RTC_PRODUCERS: 'RTC_PRODUCERS',
    RTC_RESET_PRODUCERS: 'RTC_RESET_PRODUCERS',
    RTC_LEAVE: 'RTC_LEAVE',
    RTC_ROOM_ID: 'RTC_ROOM_ID',
    RTC_CONSUMERS: 'RTC_CONSUMERS',
    RTC_NEW_PEER: 'RTC_NEW_PEER',
    RTC_CALL: 'RTC_CALL',
    RTC_CLOSE: 'RTC_CLOSE',
    RTC_ANSWER: 'RTC_ANSWER',
    RTC_SET_COUNTERPART: 'RTC_SET_COUNTERPART',

    MESSAGES_ADD_ROOM_UNREAD: 'MESSAGES_ADD_ROOM_UNREAD',
    MESSAGES_REMOVE_ROOM_UNREAD: 'MESSAGES_REMOVE_ROOM_UNREAD',

    REFRESH_MEETINGS: 'REFRESH_MEETINGS',
};

export default Actions;
