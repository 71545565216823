import axios from "axios";
import config from "./config"

const access_token=localStorage.getItem('laravel_access_token')

const instance= axios.create({
  baseURL: config.base_url,
  headers: {
    "X-Authorization": "Bearer "+access_token
    // "X-Authorization": "Bearer ZnHAOSJsyPD7oOCbRu9vhTEwwSbtEaPnJEHSWjVPadmx9V2cjWeSuPfscxUG"
  },
  user_role:"",
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  const originalRequest = error.config;
  console.log(error)
  if (401 === error.response.status) {
    console.log("Unauthorized Request",originalRequest);
    // alert("Unauthorized Request");
    // TODO
    window.location.href = config.logout_url;
} else {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
}
});

export default instance;
