import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import "./Panel.sass";
import TopBar from "./components/TopBar";
import SearchBar from "./components/SearchBar";
import NavBar from "./components/NavBar";
import MeetingBar from "./components/MeetingBar";
import Room from "./components/Room";
import Meeting from "./components/Meeting";
import User from "./components/User";
import getRooms from "../../actions/getRooms";

import search from "../../actions/search";
import getFavorites from "../../actions/getFavorites";
import getMeetings from "../../actions/getMeetings";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../constants/Actions";
import Settings from "./components/Settings";
import { useLocation } from "react-router-dom";
import MyFriend from "./components/MyFriend";
import Group from "./components/Group";

import getNewRequests from "../../actions/getNewRequests";
import getMyFriends from "../../actions/getMyFriends";
import getColleagues from "../../actions/getColleagues";
import getGroups from "../../actions/getGroups";
import config from '../../config';



import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import ChatPlaceholder from './ChatPlaceHolder'


const Panel = () => {
  const nav = useGlobal('nav')[0];
  const token = useGlobal('token')[0];
  const searchText = useGlobal('search')[0];

  const rooms = useSelector(state => state.io.rooms);

  // Panel
  const newrequests = useSelector(state => state.io.newrequests);
  const myfriends = useSelector(state => state.io.myfriends);
  const colleagues = useSelector(state => state.io.colleagues);
  const groups = useSelector(state => state.io.groups);
  const [searchResults, setSearchResults] = useGlobal('searchResults');
  const [favorites, setFavorites] = useGlobal('favorites');
  const [meetings, setMeetings] = useGlobal('meetings');
  const [callStatus, setCallStatus] = useGlobal('callStatus');
  const [over, setOver] = useGlobal('over');
  const refreshMeetings = useSelector(state => state.io.refreshMeetings);

  const dispatch = useDispatch();
  const location = useLocation();

  const [GropListLoader, setGropListLoader] = useGlobal("GropListLoader");

  useEffect(() => {
    // **********************
    setTimeout(async () => {
      await getRooms()
      .then((res) =>
        dispatch({
          type: Actions.SET_ROOMS,
          rooms: res.data.rooms,
        })
      )
      .catch((err) => console.log(err));
      // Panel
      await getNewRequests()
        .then((res) =>
          dispatch({
            type: Actions.SET_NEW_REQUESTS,
            rooms: res.data.rooms,
          })
        )
        .catch((err) => console.log(err));

      await getMyFriends()
        .then((res) =>
          dispatch({
            type: Actions.SET_MY_FRIENDS,
            rooms: res.data.rooms,
          })
        )
        .catch((err) => console.log(err));

      await getColleagues()
        .then((res) =>
          dispatch({
            type: Actions.SET_COLLEAGUES,
            users: res.data.users,
          })
        )
        .catch((err) => console.log(err));

      await getGroups()
        .then((res) => {
          dispatch({
            type: Actions.SET_GROUPS,
            groups: res.data.rooms,
          });
        })
        .catch((err) => console.log(err));

      search().then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
      getFavorites().then(res => setFavorites(res.data.rooms)).catch(err => console.log(err));
      getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));
      setGropListLoader(false);


    }, 500);
    // **********************

  

  }, [setSearchResults, setFavorites]);

  // useEffect(() => {
  //   getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));
  // }, [refreshMeetings]);

  
  const [activeChatBoder, setactiveChatBoder] = useGlobal("activeChatBoder");
  //  Panel 
  const roomsList = rooms.map(room =>(
  <div className={`${activeChatBoder==room._id? 'rightActive':""}`}
  onClick={()=>{setactiveChatBoder(room._id)}}
  > <Room key={room._id} room={room} /></div>
  ));
  const newRequestsList = newrequests.map(room =>(
   <div className={`${activeChatBoder==room._id? 'rightActive':""}`}
   onClick={()=>{setactiveChatBoder(room._id)}}
   > <MyFriend key={room._id} room={room} /></div>
  ));
  const myFriendsList = myfriends.map(room => (
    <div className={`${activeChatBoder==room._id? 'rightActive':""}`}
    onClick={()=>{setactiveChatBoder(room._id)}}
    > <MyFriend key={room._id} room={room} /></div>
  ));
  const colleaguesList = colleagues.map(user => (
    <div className={`${activeChatBoder==user._id? 'rightActive':""}`}
    onClick={()=>{setactiveChatBoder(user._id)}}
    > <User key={user._id} user={user} /></div>
  ));
  const groupsList = groups.map(room =>(
    <div className={`${activeChatBoder==room._id? 'rightActive':""}`}
    onClick={()=>{setactiveChatBoder(room._id)}}
    >  <Group key={room._id} room={room} /></div>
  ));

  const searchResultsList = searchResults.map(user => (
    <div className={`${activeChatBoder==user._id? 'rightActive':""}`}
    onClick={()=>{setactiveChatBoder(user._id)}}
    >  <User key={user._id} user={user} /></div>
  ));
  const favoritesList = favorites.map(room => (
     <div className={`${activeChatBoder==room._id? 'rightActive':""}`}
     onClick={()=>{setactiveChatBoder(room._id)}}
     > <Room key={room._id} room={room} /></div>
    
  ));
  const meetingsList = meetings.map(meeting =>(
     <div className={`${activeChatBoder==meeting._id? 'rightActive':""}`}
     onClick={setactiveChatBoder(meeting._id)}
     ><Meeting key={meeting._id} meeting={meeting} /></div>
 
  ));

  const Notice = ({ text }) => <div className="notice">{text}</div>
  const [favoritesActive, setfavoritesActive] = useState(false);
  const activeFavratee = (e) => {
    setfavoritesActive(e);
  }


  return (
    <div className="panel">
      <TopBar />
      <NavBar activeFavrate={activeFavratee} />
      <SearchBar
        activeFavrate={activeFavratee}
        favoritesList={favoritesActive}
      />
      {callStatus === "in-call" &&
        (!location.pathname.startsWith("/meeting") || over === false) && (
          <MeetingBar />
        )}


      {GropListLoader ? (
        <ChatPlaceholder />
      ) : (
        <div className="rooms">
          {nav === "new_requests" && newRequestsList}
          {nav === "new_requests" && newRequestsList.length === 0 && (
            <Notice text={`There are no new requests!`} />
          )}
          {nav === "my_friends" && myFriendsList}
          {nav === "my_friends" && myFriendsList.length === 0 && (
            <Notice text="There are no friends yet. Message someone to begin!" />
          )}
          {nav === "colleagues" && colleaguesList}
          {nav === "colleagues" && colleaguesList.length === 0 && (
            <Notice text="There are no colleagues yet!" />
          )}
          {nav === "groups" && groupsList}
          {nav === "groups" && groupsList.length === 0 && (
            <Notice text="There are no groups yet!" />
          )}
          {nav === "settings" && <Settings />}
        </div>
      )}
    </div>
  );
}

export default Panel;
