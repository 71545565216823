import axios from "axios";
import Config from "../config";

const getRoom = (id, searched) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/join",
        data: { id, searched }
    });
};

export default getRoom;
