import axios from "axios";
import Config from "../config";

const getNewRequests = (selectedsearch, searched) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/newrequests/list",
        data: { limit: 30, search: selectedsearch, searchcontent: searched }
    });
};

export default getNewRequests;
