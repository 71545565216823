import axios from "axios";
import Config from "../config"; 

const profileSettingStudent = (data) => { 
  return axios({
    method: "post",
    url: (Config.url || "") + "/api/student/update",
    data:data,
  });
};

export default profileSettingStudent;
