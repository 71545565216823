import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

let ChatPlaceholder = ()=>{
      return(
           <>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
            <ReactPlaceholder showLoadingAnimation={true} className='ReactPlaceholder-x' type='media' ready={false} rows={3}></ReactPlaceholder>
           </>
      )
}

export default ChatPlaceholder;