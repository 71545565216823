import axios from "axios";
import Config from "../config";

const removePicture = messageID => {
    return axios({
        method: "post",
        url: (Config.url || '') + '/api/message/remove',
        data: { messageID }
    });
};

export default removePicture;
